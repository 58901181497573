import k from "./keys";

export default {
  [k._]: `:`,
  [k._1]: `(`,
  [k._2]: `)`,
  [k.LIGHT]: `Luz`,
  [k.ANGEL]: `Ángel`,
  [k.DARK]: `Oscuro`,
  [k.DEMON]: `Demonio`,
  [k.SELECT_ALL]: `Seleccionar todo`,
  [k.DESELECT_ALL]: `Deseleccionar todo`,
  [k.ALSBVYETHVN]: `alsbvyethvn`,
  [k.ALASKA]: `alaska`,
  [k.BSKJFH_KSJLHFVKLFSJB]: `bskjfh ksjlhfvklfsjb`,
  [k.CPQWHG_SKUGBHOWSFVJH]: `cpqwhg skugbhowsfvjh`,
  [k.DWQEFHKDVB_LASHDVSDALN_SJBVKSF]: `dwqefhkdvb lashdvsdaln sjbvksfjbv`,
  [k.SDV_ASODJHVSF_SLDHGVKSUFGV]: `sdv ;asodjhvsf sldhgvksufgv`,
  [k.KJSBFV]: `kjsbfv`,
  [k.POWQJIF]: `powqjif`,
  [k.XCUYWE]: `xcuywe`,
  [k.POIQRF]: `poiqrf`,
  [k.LABEL]: `etiqueta`,
  [k.NONE]: `Ninguno`,
  [k.SYSTEM]: `Sistema`,
  [k._3]: `0`,
  [k.CHECKBOX]: `casilla de verificación`,
  [k.LOADING_CHART]: `Cargando gráfico`,
  [k.ADD_A_CHART]: `+ Añadir un gráfico`,
  [k.CREATED_BY]: `Creado por:`,
  [k.DELETE_DASHBOARD]: `Eliminar dashboard`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET]: `¿Estás seguro de que deseas eliminar`,
  [k.PINNED_CHARTS_WILL_NOT_BE_DELE]: `Los gráficos fijados no serán eliminados.`,
  [k.CANCEL]: `Cancelar`,
  [k.DELETE]: `Eliminar`,
  [k.DASHBOARD]: `Dashboard`,
  [k.CREATE_NEW]: `+ Crear nuevo`,
  [k.REFRESH_DASHBOARD]: `Refrescar dashboard`,
  [k.DELETE_DASHBOARD1]: `Eliminar dashboard`,
  [k.BUTTON]: `botón`,
  [k.NO_DASHBOARD_SELECTED]: `No se ha seleccionado ningún dashboard`,
  [k.SELECT]: `Seleccionar`,
  [k.ADDBUTTON]: `añadirBotón`,
  [k.U_B_ADD]: `+ Añadir`,
  [k._4]: ``,
  [k._5]: `-`,
  [k.YOU_ARE_NOT_AUTHORIZED_CLICK]: `No estás autorizado. Haz clic aquí para ver los detalles.`,
  [k.ADD]: `añadir`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_CANCE]: `¿Estás seguro de que deseas cancelar la aprobación? Esto desbloqueará los campos de la lista de verificación, cambiará el estado y degradará la aprobación previa.`,
  [k.PLEASE_ENTER_A_COMMENT]: `Por favor, ingresa un comentario`,
  [k.NO]: `No`,
  [k.YES]: `Sí`,
  [k.CANCELLATION]: `Cancelación -`,
  [k.HIDE]: `Ocultar`,
  [k.SHOW]: `Mostrar`,
  [k.LINE_THROUGH]: `line-through`,
  [k.INCOMPLETE]: `Incompleto`,
  [k.CONFIGURE_AD_HOC_SIGNATURE]: `Configurar Firma Ad-hoc`,
  [k.NUMBER_OF_SIGNATORIES]: `Número de signatarios`,
  [k.USERS]: `Usuarios`,
  [k.GROUPS]: `Grupos`,
  [k.CONFIRM]: `Confirmar`,
  [k.REQUEST_APPROVAL]: `Solicitar Aprobación`,
  [k.ADD_SIGNATURE]: `+ Añadir Firma`,
  [k.APPLY_YOUR_SIGNATURE]: `Aplicar tu firma`,
  [k.TYPE]: `Tipo`,
  [k.APPROVAL]: `Aprobación`,
  [k.REJECTION]: `Rechazo`,
  [k.EMAIL_ID]: `ID de Email`,
  [k.OTP_SENT_AGAIN_PLEASE_CHECK_Y]: `OTP enviado de nuevo. Por favor, revisa tu email.`,
  [k.OTP_SENT_TO_THE_MENTIONED_EMAI]: `OTP enviado a la dirección de email mencionada.`,
  [k.RESEND]: `Reenviar`,
  [k.PASSWORD]: `Contraseña`,
  [k.OTP]: `OTP`,
  [k.INCORRECT_OTP]: `OTP incorrecto`,
  [k.GENERATE_OTP]: `Generar OTP`,
  [k.APPROVAL1]: `aprobación`,
  [k.COMMENT]: `Comentario`,
  [k.UPLOAD_YOUR_OWN_SIGNATURE]: `Subir tu propia firma`,
  [k.ATTACH_FILE]: `Adjuntar archivo`,
  [k.USE_SYSTEM_GENERATED_SIGNATURE]: `Usar firma generada por el sistema`,
  [k.CONFIRM_AND_SIGN]: `Confirmar y firmar`,
  [k.ENTER_EMAIL]: `Ingrese email`,
  [k.UPLOAD_NEW]: `Subir nuevo`,
  [k.UPLOAD]: `Subir`,
  [k.BY]: `por:`,
  [k.YOU]: `Tú`,
  [k.ID]: `ID:`,
  [k.MEMBERS]: `miembros`,
  [k.MEMBER]: `miembro`,
  [k.USER]: `usuario-`,
  [k.GROUP]: `grupo-`,
  [k.REMOVE_SELECTED_USER]: `Eliminar usuario seleccionado`,
  [k.LIST]: `lista`,
  [k.USER1]: `usuario`,
  [k.GROUP1]: `grupo`,
  [k.LINK]: `enlace`,
  [k.ENTER_USER_S_EMAIL]: `Ingrese el email del usuario`,
  [k.ADD_NEW_USER]: `Añadir nuevo usuario`,
  [k.SELECT_USERS]: `Seleccionar usuarios`,
  [k.ENTER_VALID_EMAIL]: `Ingrese un email válido`,
  [k.TOGGLEUSERDROPDOWN]: `toggleUserDropdown`,
  [k.REMOVEUSER]: `removeUser`,
  [k.DECREMENT]: `decrement`,
  [k.NO_USER_SELECTED]: `Ningún usuario seleccionado`,
  [k.ADD1]: `Añadir`,
  [k.ENTER_TITLE]: `Ingrese título`,
  [k.SEARCH]: `Buscar`,
  [k.PRIVATE]: `Privado`,
  [k.LOADING]: `Cargando...`,
  [k.CREATE]: `+ Crear`,
  [k.REMOVE]: `Eliminar`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_REMOV]: `¿Estás seguro de que deseas eliminar`,
  [k.FROM_THE_CHECKLIST]: `de la lista de verificación?`,
  [k.CONVERSATION]: `conversación`,
  [k.VIEW_ALL_FIELDS]: `Ver todos los campos`,
  [k.UPDATE]: `Actualizar`,
  [k._7]: `#`,
  [k.NO_TITLE]: `Sin título`,
  [k.MAKE_AS_CURRENT_REVISION]: `Hacer como revisión actual`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_MAKE]: `¿Estás seguro de que deseas hacer`,
  [k.AS_THE_CURRENT_REVISION]: `como la revisión actual?`,
  [k.CURRENT_REVISION]: `Revisión actual`,
  [k.CURRENTREVISION]: `currentRevision`,
  [k.CURRENT]: `Actual`,
  [k.MAKE_CURRENT_REVISION]: `Hacer revisión actual`,
  [k.MAKE_CURRENT]: `Hacer actual`,
  [k.VIEWING_NOW]: `Viendo ahora`,
  [k.NO_TITLE1]: `Sin Título`,
  [k.DONE]: `Hecho`,
  [k.MORE]: `...más`,
  [k.UNIFIZE_UNIFIZEBLUE]: `unifize_unifizeBlue`,
  [k.CREATE_NEW1]: `Crear nuevo`,
  [k.DOCUMENT]: `documento`,
  [k.ENTER_TITLE1]: `Ingrese Título`,
  [k.NOTE_YOUR_DOCUMENT_WILL_BE_SH]: `Nota: Tu documento será compartido con todos los participantes de esta
            conversación`,
  [k.CREATE1]: `Crear`,
  [k.FILEICONS]: `fileIcons`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET1]: `¿Estás seguro de que deseas eliminar "`,
  [k.INLINE_BLOCK]: `inline-block`,
  [k._9]: `.`,
  [k._10]: `"`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_REMOV1]: `¿Estás seguro de que deseas eliminar este archivo?`,
  [k.REMOVE_FROM_CHECKLIST]: `Eliminar de la lista de verificación`,
  [k.REM]: `0.5rem`,
  [k.FROM_UNIFIZE_FILE_STORE]: `Desde el almacén de archivos de Unifize`,
  [k.UPLOADING_FILE]: `Subiendo archivo...`,
  [k.OTHERS]: `Otros`,
  [k.PREVIEW]: `preview`,
  [k.EDIT]: `edit3`,
  [k.FILECONTAINER]: `fileContainer`,
  [k.FILENAME]: `fileName`,
  [k._11]: `|`,
  [k.UPLOADED_ON]: `Subido el:`,
  [k.NO_PREVIEW_AVAILABLE]: `No hay vista previa disponible`,
  [k.CHECKLIST]: `checklist`,
  [k.PICK_SOURCE]: `Seleccionar fuente`,
  [k.UNIFIZE_FILE_STORE]: `Almacén de archivos de Unifize`,
  [k.COMPUTER]: `Computadora`,
  [k.NO_SOURCE_SELECTED]: `No se ha seleccionado ninguna fuente`,
  [k._12]: `,`,
  [k.SELECT_FORM]: `+ Seleccionar formulario`,
  [k.AUTO_SELECT_ALL_REVISIONS]: `Auto-seleccionar todas las revisiones`,
  [k.SELECT_MANUALLY]: `Seleccionar manualmente`,
  [k.BACK]: `Atrás`,
  [k.AUTO_SELECT_ALL_REVISIONS_IS]: `Auto-seleccionar todas las revisiones está`,
  [k.EDIT_SELECTED_REVISION_S]: `Editar revisión(s) seleccionada(s)`,
  [k.ALL_REVISIONS]: `Todas las revisiones`,
  [k.SELECT_REVISIONS]: `Seleccionar revisiones`,
  [k.REMOVE_LINK_OR_REASSIGN_REVISI]: `Eliminar enlace o reasignar revisiones?`,
  [k.THIS_CONVERSATION_HAS_MULTIPLE]: `Esta conversación tiene múltiples revisiones. ¿Quieres eliminar el
                enlace a todas ellas?`,
  [k.REASSIGN_REVISIONS]: `Reasignar revisiones`,
  [k.REMOVE_LINK]: `Eliminar enlace`,
  [k.ADDCONVERSATIONBUTTON]: `addConversationButton`,
  [k.AUTO_SELECT_CURRENT_REVISION]: `Auto-seleccionar revisión actual`,
  [k.AUTO_SELECT_CURRENT_REVISION_I]: `Auto-seleccionar revisión actual está`,
  [k.EDIT_SELECTED_REVISION]: `Editar revisión seleccionada`,
  [k.SELECT_REVISION]: `Seleccionar revisión`,
  [k.NEW_ITEM]: `nuevo item`,
  [k.ITEM]: `item "`,
  [k.SELECT_CHECKLIST_FIELDS]: `Seleccionar campos de la lista de verificación`,
  [k.CREATE_VERSION]: `Crear versión`,
  [k.CREATE_NEW_REVISION]: `Crear nueva revisión`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_CREAT]: `¿Estás seguro de que deseas crear una revisión?`,
  [k.CANCELLERS]: `canceladores`,
  [k.ALLOWED_CANCELLERS]: `Canceladores permitidos`,
  [k.ALLOWED_SIGNATORIES]: `Signatarios permitidos`,
  [k.ROLES]: `Roles`,
  [k.FIELDS]: `Campos`,
  [k.SPECIFIC_USERS]: `Usuarios específicos`,
  [k.SELECT_THE_DATA_TO_COPY_TO_NEW]: `Seleccionar los datos para copiar a la nueva revisión`,
  [k.CHECKLIST_AND_FORM_DATA]: `Datos de la lista de verificación y del formulario`,
  [k.SELECT_FIELDS]: `Seleccionar campos`,
  [k.REVISION_NOTES]: `Notas de la revisión`,
  [k.WHY_ARE_YOU_MAKING_THIS_REVISI]: `¿Por qué estás haciendo esta revisión?`,
  [k.CREATE_REVISION]: `Crear revisión`,
  [k.REVISION]: `Revisión`,
  [k.CREATE_REVISION1]: `Crear Revisión`,
  [k.CONVERSATION1]: `Conversación`,
  [k.NO_MATCHING_RESULTS]: `no hay resultados coincidentes.`,
  [k.ADD_NEW_USER1]: `+ Añadir nuevo usuario`,
  [k.CHECKLISTREMOVE]: `checklistRemove`,
  [k.UPLOAD_NEW_VERSION]: `Subir nueva versión`,
  [k.DOWNLOAD]: `Descargar`,
  [k.MMM_DD_YYYY]: `MMM DD, YYYY`,
  [k.NOT_ASSIGNED]: `No asignado`,
  [k.SEND_AN_EMAIL_TO_CONVERSATION]: `Enviar un email a la conversación`,
  [k.FILES]: `Archivos`,
  [k.DATA]: `Datos`,
  [k.INFO]: `Info`,
  [k.BACK1]: `atrás`,
  [k.CHECKLIST1]: `Lista de verificación`,
  [k.JSON_FOR_ADOBE]: `JSON para Adobe`,
  [k.JSON_FOR_PDFGENERATORAPI]: `JSON para PDFGeneratorAPI`,
  [k.SAVE]: `Guardar`,
  [k.REFRESH]: `Refrescar`,
  [k.TO_SEE_LATEST_CHECKLIST_DATA]: `para ver los últimos datos de la lista de verificación`,
  [k.CREATOR]: `Creador`,
  [k.DATE_CREATED]: `Fecha de creación`,
  [k.MMM_DD_YYYY_HH_MM_A]: `MMM DD, YYYY hh:mm a`,
  [k.AGE]: `Edad`,
  [k.DAYS]: `días`,
  [k.DAYS1]: `Días`,
  [k.PARENT1]: `Padre:`,
  [k.CHILD]: `, Hijo:`,
  [k.LEAVE_CONVERSATION]: `Salir de la Conversación`,
  [k.PUBLIC]: `Público`,
  [k.HIDE_CHAT_CONTENT]: `Ocultar contenido del chat`,
  [k.HIDE_EVERYTHING]: `Ocultar todo`,
  [k.ADD_NEW_FIELDS]: `Añadir nuevos campos`,
  [k.PRIVACY_SETTINGS]: `Configuraciones de privacidad`,
  [k.NORMAL]: `Normal`,
  [k.MAKE_PRIVATE]: `Hacer privado`,
  [k.ONLY_PARTICIPANTS_CAN_ACCESS_T]: `Solo los participantes pueden acceder a estas conversaciones`,
  [k.HIDE_ONLY_CHAT_CONTENT]: `Ocultar solo el contenido del chat`,
  [k.ONLY_THE_CONTENTS_OF_THE_CONVE]: `Sólo se ocultará el contenido del registro. El título, el estado y el propietario del registro serán visibles para otros usuarios.`,
  [k.CONVERSATIONS_WILL_BE_VISIBLE]: `Las conversaciones solo serán visibles para los participantes, admin(s),
              y usuarios en la lista blanca.`,
  [k.REMINDERS]: `recordatorios`,
  [k.BELL]: `campana`,
  [k.REMINDERS1]: `Recordatorios`,
  [k.WHATSAPP]: `whatsapp`,
  [k.ON]: `Encendido`,
  [k.OFF]: `Apagado`,
  [k.LEFTHANDLE]: `manijaIzquierda`,
  [k.DOWNLOAD_PROHIBITED]: `Descarga prohibida`,
  [k.DOWNLOADING_FILES_IS_PROHIBITE]: `La descarga de archivos está prohibida por el admin.`,
  [k.WE_RE_SORRY_SOMETHING_S_GONE]: `Lo siento, algo salió mal.`,
  [k.OUR_TEAM_HAS_BEEN_NOTIFIED_BU]: `Nuestro equipo ha sido notificado, pero`,
  [k.LET_US_KNOW]: `háganos saber`,
  [k.WHAT_HAPPENED]: `qué pasó.`,
  [k.BROWSER]: `Navegador:`,
  [k.VERSION]: `Versión:`,
  [k.UID]: `UID:`,
  [k.URL]: `URL:`,
  [k.ORG]: `Org:`,
  [k.TRY_AGAIN]: `Intentar de nuevo`,
  [k.CHROME]: `Chrome`,
  [k.FIREFOX]: `Firefox`,
  [k.MICROSOFT_INTERNET_EXPLORER]: `Microsoft Internet Explorer`,
  [k.SAFARI]: `Safari`,
  [k.EDGE]: `Edge`,
  [k.OPERA]: `Opera`,
  [k.COULD_NOT_FETCH_CHAT_ROOMS]: `No se pudieron obtener las salas de chat:`,
  [k.RETRY]: `Reintentar`,
  [k._13]: `+`,
  [k.SCROLLCOLUMN_D]: `scrollColumn2d`,
  [k.HOME]: `Inicio`,
  [k.WELCOME_BACK]: `¡Bienvenido de nuevo!`,
  [k.LOADING_USER]: `Cargando usuario...`,
  [k.YOUR_HOME_SCREEN_HAS_NOT_BEEN]: `Tu pantalla de inicio no ha sido configurada por el admin de tu org.`,
  [k.UNIFIZE_HOME_NOT_SET_UP]: `La pantalla de inicio de Unifize no está configurada`,
  [k.CONTACT_YOUR_ORG_ADMIN_TO_SET]: `Contacta al admin de tu org para configurar tu pantalla de inicio de Unifize`,
  [k.HERE_S_WHAT_S_GOING_ON_AT]: `Esto es lo que está sucediendo en`,
  [k.CLOSE1]: `Cerrar`,
  [k.SKIP_INVITE]: `Saltar invitación`,
  [k.DO_NOT_ADD_CREATOR_AS_OWNER]: `No agregar al creador como propietario`,
  [k.DO_NOT_ADD_CREATOR_AS_PARTICIP]: `No agregar al creador como participante`,
  [k.DO_NOT_SEND_CONFIRMATION_TO_SE]: `No enviar confirmación al remitente`,
  [k.DISABLE_AUTO_NUMBER]: `Desactivar número automático`,
  [k.CONVERT_TO_FEED]: `Convertir en feed`,
  [k.SLA_IN_DAYS_AUTO_FILL_DUE_DAT]: `SLA en días (Rellenar automáticamente la fecha de vencimiento cuando se crea la conversación)`,
  [k.EMAILS_SENT_HERE_WILL_START_A]: `Los correos electrónicos enviados aquí iniciarán una nueva instancia`,
  [k.DO_NOT_SHOW_THIS_PROCESS_IN]: `No mostrar este proceso en + NUEVO`,
  [k.DO_NOT_INVITE_SENDER_TO_CONVER]: `No invitar al remitente a la conversación`,
  [k.NEXT_COUNT]: `Siguiente conteo`,
  [k.NOTIFY_GROUPS]: `Notificar a los grupos`,
  [k.ENABLE_PAGINATION_IN_PROCESS_T]: `Habilitar paginación en la tabla de procesos`,
  [k.ENABLE_PAGINATION_IN_REPORTS]: `Habilitar paginación en los informes`,
  [k.ALLOW_ADHOC_ARCHIVING]: `Permitir archivado ad hoc`,
  [k.ADD_FIELD]: `Agregar campo`,
  [k.FIELD1]: `Campo`,
  [k.DOWNLOAD_SAMPLE_CSV]: `Descargar CSV de ejemplo`,
  [k.ADD_FIELD1]: `Agregar campo`,
  [k.TEXT_CSV]: `texto/csv`,
  [k.BLUE]: `azul`,
  [k.UNDERLINE]: `subrayado`,
  [k.BEHAVIOR]: `Comportamiento`,
  [k.MANDATORY_IF_NOT_HIDDEN]: `Obligatorio si no está oculto`,
  [k.VALUE]: `Valor`,
  [k.INK]: `tinta400`,
  [k.DEPENDENTFORMINCLUDE]: `Incluir formulario dependiente`,
  [k.DEPENDENTFORMEXCLUDE]: `Excluir formulario dependiente`,
  [k.END]: `fin`,
  [k._16]: `40%`,
  [k.CONDITION]: `Condición`,
  [k.FORM_DATA]: `DATOS DEL FORMULARIO`,
  [k.CHECKLIST_DATA]: `DATOS DE LA LISTA DE VERIFICACIÓN`,
  [k.CONVERSATION_DATA]: `DATOS DE LA CONVERSACIÓN`,
  [k.SHOW_UNTIL_CONDITION_MET]: `Mostrar hasta que se cumpla la condición`,
  [k.HIDDEN_UNTIL_CONDITION_MET]: `Oculto hasta que se cumpla la condición`,
  [k.MANDATORY_UNTIL_CONDITION_MET]: `Obligatorio hasta que se cumpla la condición`,
  [k.DISABLE_UNTIL_CONDITION_MET]: `Desactivar hasta que se cumpla la condición`,
  [k.COPY]: `Copiar`,
  [k.ADD_CONDITION_AND]: `+ Agregar condición (Y)`,
  [k.ADD_NEW_CONDITION]: `+ Agregar nueva condición`,
  [k.DELETE_FIELD]: `Eliminar campo`,
  [k._17]: `?`,
  [k.FIELDNAMEINPUT]: `campoNombreEntrada-`,
  [k.FIELDSETTINGS]: `Configuracióan Campo`,
  [k.FORM]: `formulario`,
  [k.NOT_ALLOWED]: `no-permitido`,
  [k.OLDSETTINGS]: `configuraciónAntigua`,
  [k.PROMPT_WHOM]: `Indicar a quién`,
  [k.WHEN]: `Cuándo`,
  [k.CREATE_RULES_TO_PROMPT_USERS_T]: `Crear reglas para indicar a los usuarios que completen este paso. Los usuarios especificados
          verán estos campos dentro de las conversaciones.`,
  [k.CREATE_NEW_RULE]: `Crear nueva regla`,
  [k.UPDATE1]: `actualizar`,
  [k.SET]: `establecer`,
  [k.REMOVE1]: `eliminar`,
  [k.AUTO_ARCHIVE]: `Auto archivar`,
  [k.SEND_MESSAGE]: `Enviar mensaje`,
  [k.CHANGE_STATUS_TO]: `Cambiar estado a`,
  [k.ADD_PARTICIPANTS]: `Agregar participantes`,
  [k.REMOVE_PARTICIPANTS]: `Eliminar participantes`,
  [k.UPDATE_PRIVACY]: `Actualizar privacidad`,
  [k.WHITELIST]: `Lista blanca`,
  [k.NOCHANGE]: `sinCambios`,
  [k.FULL]: `completo`,
  [k.DON_T_CHANGE]: `No cambiar`,
  [k.MAKE_PUBLIC]: `Hacer público`,
  [k.START]: `comenzar`,
  [k.UNARCHIVE]: `Desarchivar`,
  [k.IS_NOT_SUPPORTED]: `no es compatible`,
  [k.GET_SIGNATURE_OF_ANY]: `Obtener firma de cualquier`,
  [k.USER_S]: `usuario(s)`,
  [k.SELECT_AD_HOC]: `Seleccionar ad-hoc`,
  [k.WHO_CAN_CANCEL]: `Quién puede cancelar`,
  [k.WHEN_CAN_THEY_CANCEL]: `Cuándo pueden cancelar`,
  [k.HIDE_WHEN_INACTIVE]: `Ocultar cuando esté inactivo`,
  [k.DISABLE_WHEN_INACTIVE]: `Desactivar cuando esté inactivo`,
  [k.CONTINUE_TO_LOCK_FIELDS_UNDER]: `Continuar para bloquear campos bajo la aprobación anterior`,
  [k.LOCK_CHECKLIST_FIELDS_DURING_A]: `Bloquear campos de la lista de verificación durante y después de la aprobación/rechazo:`,
  [k.LOCK_STATUS_PREVENT_USERS_FRO]: `Bloquear estado (prevenir que los usuarios cambien el estado)`,
  [k.CHANGE_OWNER_TO]: `Cambiar propietario a`,
  [k.SEND_A_MESSAGE]: `Enviar un mensaje`,
  [k.MARK_REVISION_AS_CURRENT]: `Marcar revisión como actual`,
  [k.CANCEL_ALL_PREVIOUS_APPROVALS]: `Cancelar todas las aprobaciones anteriores (dentro de la jerarquía)`,
  [k.SELECT_CHECKLIST_FIELD]: `Seleccionar campo de la lista de verificación`,
  [k.DELETED_FIELD]: `Campo eliminado`,
  [k.WORKFLOW]: `flujo de trabajo`,
  [k.TASK]: `tarea`,
  [k.PROCESS]: `Proceso`,
  [k.EMBEDDED_FIELDS]: `CAMPOS INCORPORADOS`,
  [k.ITEM1]: `ítem-`,
  [k.ADD_EMBEDED_FIELD]: `Agregar campo incrustado`,
  [k.ONLY_ALLOW_CREATING_NEW_CONVER]: `Solo permitir crear nuevas conversaciones`,
  [k.ONLY_ALLOW_SELECTING_EXISTING]: `Solo permitir seleccionar conversaciones existentes`,
  [k.ALLOW_BOTH_SELECTING_AND_CREAT]: `Permitir tanto seleccionar como crear`,
  [k.ALLOW_MULTIPLE]: `Permitir múltiples`,
  [k.ALLOW_ARCHIVED_CONVERSATIONS]: `Permitir conversaciones archivadas`,
  [k.SHOW_FIELD_NAMES_IN_PREVIEW]: `Mostrar nombres de campo en la vista previa`,
  [k.SHOW_STATUS_DUE_DATE]: `Mostrar estado, fecha de vencimiento`,
  [k.AUTO_FILL_RELATED_CONVERSATION]: `Rellenar automáticamente la conversación relacionada`,
  [k.SUPRESS_TITLE_HYPERLINK_TO_THE]: `Suprimir hipervínculo de título al registro`,
  [k.SORTBY]: `Ordenar por:`,
  [k.CREATED_DATE]: `Fecha de creación`,
  [k.ADDED_DATE]: `Fecha de añadido`,
  [k.SELECT_EXISTING_BY]: `Seleccionar existente por:`,
  [k.TITLE]: `Título`,
  [k.PLACEHOLDER]: `Marcador de posición`,
  [k.ALLOW_OFFICE_INTEGRATION]: `Permitir integración de Office 365`,
  [k.YOU_MUST_SELECT_A_DEFAULT_FOLD]: `Debes seleccionar una carpeta predeterminada en sharepoint/office365 para subir o
          crear archivos.`,
  [k.AUTHENTICATE_AND_SELECT_FOLDER]: `Autenticar y seleccionar carpeta`,
  [k.VIEW]: `Vista`,
  [k.FOLDER]: `Carpeta`,
  [k.CONSUMPTION_OPTIONS]: `Opciones de consumo:`,
  [k.ALLOW_SINGLE]: `Permitir único`,
  [k.PREVIEW_OPTIONS]: `Opciones de vista previa:`,
  [k.SHOW_FULL_PREVIEW]: `Mostrar vista previa completa`,
  [k.SORT_BY]: `Ordenar por:`,
  [k.DATE]: `Fecha`,
  [k.NAME]: `Nombre`,
  [k.DEFAULT_FILE]: `Archivo predeterminado:`,
  [k.SUPPRESS_NOTIFICATIONS_ON_DEFA]: `Suprimir notificaciones en valores predeterminados`,
  [k.FILE_UPLOAD_OPTIONS]: `Opciones de carga de archivos:`,
  [k.UPLOAD_FROM_COMPUTER]: `Subir desde el ordenador`,
  [k.OFFICE_ONEDRIVE_SHAREPOI]: `Office 365 OneDrive / Sharepoint`,
  [k.FOLDER_PATH]: `Ruta de la carpeta`,
  [k.THE_CREATED_FILE_CAN_BE_EDITED]: `El archivo creado puede ser editado por los miembros de la sala de chat`,
  [k.ADD_A_FORM]: `+ Agregar un formulario`,
  [k.AUTO_FILL_RELATED_CONVERSATION1]: `Rellenar automáticamente las conversaciones relacionadas`,
  [k.SHOW_COMPRESSED_PREVIEW]: `Mostrar vista previa comprimida`,
  [k.SHOW_STATUS_OWNER_AND_DUE_DAT]: `Mostrar estado, propietario y fecha de vencimiento`,
  [k.SUPPRESS_TITLE_HYPERLINK_TO_TH]: `Suprimir hipervínculo de título al registro`,
  [k.LINKED_FIELD_RELATION]: `Relación de campo vinculado:`,
  [k.CHILD1]: `hijo`,
  [k.PARENT2]: `Padre`,
  [k.CHILD2]: `Hijo`,
  [k.OTHER_OPTIONS]: `Otras opciones:`,
  [k.ALLOW_ONLY_SELECTING]: `Permitir solo seleccionar`,
  [k.ALLOW_SELECTING_AND_CREATING]: `Permitir seleccionar y crear`,
  [k.ALLOW_ONLY_CREATING]: `Permitir solo crear`,
  [k.ONLY_SHOW_LINKED_FIELDS]: `Mostrar solo campos vinculados`,
  [k.REVISIONS]: `Revisiones:`,
  [k.ALLOW_MANUAL_REVISION_LINKING]: `Permitir vinculación manual de revisiones incluso cuando`,
  [k.THERE_ARE_NO_REVISIONS]: `no hay revisiones`,
  [k.CREATED]: `creado`,
  [k.DISPOPTIONS_SELECT]: `dispOptions-select`,
  [k.DISPOPTIONS_SELECT_AND_CREATE]: `dispOptions-select-and-create`,
  [k.DISPOPTIONS_CREATE]: `dispOptions-create`,
  [k.DISPOPTIONS_SHOWONLY]: `dispOptions-showOnly`,
  [k.MAX]: `Máx`,
  [k.MIN]: `Mín`,
  [k.MAX_VALUE_SHOULD_BE_MORE_THAN]: `El valor máximo debe ser mayor que el valor mínimo.`,
  [k.STEP]: `Paso`,
  [k.MINIMUM_PLACES_TO_DISPLAY_AFTE]: `Lugares mínimos para mostrar después del punto decimal (Mostrar ceros finales
              incluso si el usuario ingresa menos dígitos de los especificados)`,
  [k.IF_THE_USER_ENTERS_MORE_DIGITS]: `Si el usuario ingresa más dígitos después del punto decimal de los
            especificados anteriormente:`,
  [k.DON_T_ROUND_OFF_OR_TRUNCATE]: `No redondear ni truncar`,
  [k.ROUND_OFF_WHEN_DISPLAYING_BUT]: `Redondear al mostrar, pero almacenar todos los dígitos tal como se ingresaron`,
  [k.DEFAULT_VALUE]: `Valor predeterminado`,
  [k.SUPPRESS_NOTIFICATIONS_FOR_DEF]: `Suprimir notificaciones para valores predeterminados`,
  [k.TYPE1]: `TIPO`,
  [k.UNIFIZEPDF]: `unifizePdf`,
  [k.UNIFIZE_US]: `unifize.us`,
  [k.DELETED_FIELD1]: `(Campo eliminado)!`,
  [k.CHECKLIST_FIELD_WHERE_NATIVE_F]: `CAMPO DE LISTA DE VERIFICACIÓN DONDE SE ENCUENTRA EL ARCHIVO NATIVO`,
  [k.UPLOAD_TEMPLATE]: `SUBIR PLANTILLA`,
  [k.DOWNLOAD1]: `descargar`,
  [k.FETCHING]: `obteniendo`,
  [k.UPLOAD_TEMPLATE1]: `Subir plantilla`,
  [k.CLICK_ON_STITCH_TO_CREATE_A_P]: `(Haz clic en Stitch para crear un PDF)`,
  [k.STITCH]: `+ Stitch`,
  [k.BUTTON_TEXT_OPTIONAL]: `Texto del botón (Opcional)`,
  [k.NAME_OF_SAVED_REPORTS_OPTIONA]: `Nombre de los informes guardados (Opcional)`,
  [k.PREVIEW_OPTIONS1]: `Opciones de vista previa`,
  [k.UPLOAD_TO_ANOTHER_FILE_FIELD]: `Subir a otro campo de archivo:`,
  [k.DON_T_ATTACH_FILE_TO_CHECKLIST]: `No adjuntar archivo a la lista de verificación`,
  [k.ATTACH_LATEST_VERSION_ONLY]: `Adjuntar solo la última versión`,
  [k.ATTACH_ALL_VERSIONS]: `Adjuntar todas las versiones`,
  [k.SORT_BY1]: `Ordenar por`,
  [k.DATE_ADDED]: `Fecha añadida`,
  [k.SEARCH_USERS]: `Buscar usuarios`,
  [k.ID1]: `id`,
  [k.NAME1]: `nombre`,
  [k.EMAIL]: `correo electrónico`,
  [k.PHOTOURL]: `photoUrl`,
  [k.OPEN_BY_DEFAULT_FOR]: `Abrir por defecto para`,
  [k.OPTIONS]: `Opciones`,
  [k.ADD_OPTIONS_DYNAMICALLY]: `Agregar opciones dinámicamente`,
  [k.DEFAULT1]: `Predeterminado`,
  [k.ALPHABETICAL]: `Alfabético`,
  [k.PENDINGVALUE]: `Valor pendiente`,
  [k.COMPLETEDVALUE]: `Valor completado`,
  [k.SELECT_A_STATUS]: `Seleccionar un estado`,
  [k.TRIANGLEDOWN]: `triánguloAbajo`,
  [k.MULTILINE]: `Multilínea`,
  [k.USER2]: `Usuario`,
  [k.MULTIPLE]: `Múltiple`,
  [k.SELECT_A_FORM_TO_ADD_CONDITION]: `Seleccionar un formulario para agregar condiciones`,
  [k.UNSTYLED]: `sinEstilo`,
  [k.D_AUTO_LAYOUT]: `Diseño automático 2D`,
  [k.MAXIMUM_FIELD_WIDTH_SMALLER_W]: `Ancho máximo del campo (anchos más pequeños permiten más campos en una fila)`,
  [k.FORCE_START_ON_A_NEW_ROW]: `Forzar inicio en una nueva fila`,
  [k.X]: `1x`,
  [k.X1]: `2x`,
  [k.X2]: `3x`,
  [k.MAX_WIDTH]: `Ancho máximo`,
  [k.CONDITIONS]: `Condiciones`,
  [k.PROMPTS]: `Indicaciones`,
  [k.LAYOUT]: `Diseño`,
  [k.FORM_CONDITIONS]: `Condiciones del formulario`,
  [k.CANCEL_CHANGES]: `Cancelar cambios`,
  [k.UPDATE_FIELD_SETTINGS]: `Actualizar configuración del campo`,
  [k.RESOLVE_AND_UPDATE]: `Resolver y actualizar`,
  [k.ME]: `yo-`,
  [k.ME1]: `Yo`,
  [k.ADDFIELDS]: `agregarCampos`,
  [k.HOLLOWPLUSCIRCLE]: `círculoMásHueco`,
  [k.ADD_FIELDS]: `Agregar campos`,
  [k.THE_CHECKLIST_HAS_NOT_BEEN_CON]: `La lista de verificación aún no ha sido configurada.`,
  [k.USE_THE_ADD_FIELD_BUTTON_TO_ST]: `Usa el botón Agregar campo para comenzar a crear o subir una hoja de Excel.`,
  [k.CHECKLIST_LAYOUT_DEFAULTS]: `Configuraciones predeterminadas del diseño de la lista de verificación`,
  [k.CHECKLIST_OPEN_BY_DEFAULT]: `¿La lista de verificación se abre por defecto?`,
  [k.WEB_APP]: `Aplicación web`,
  [k.UNIFIZE_LITE_ON_WEB]: `Unifize Lite en la web`,
  [k.UNIFIZE_LITE_ON_MOBILE_NAVIGA]: `Unifize Lite en el móvil (Navegar a la lista de verificación al abrir la conversación)`,
  [k.DEFAULT_CHECKLIST_WIDTH_ON_UNI]: `Ancho predeterminado de la lista de verificación en la aplicación web de Unifize`,
  [k.SINGLE_COLUMN]: `Una columna`,
  [k.DOUBLE_COLUMN]: `Dos columnas`,
  [k.TRIPLE_COLUMN]: `Tres columnas`,
  [k.SPLIT_AVAILABLE_SCREEN_SPACE_I]: `Dividir el espacio disponible en la pantalla en un 50% de conversación y un 50% de lista de verificación`,
  [k.ONLY_THE_CONTENTS_OF_THE_CONVE1]: `Solo el contenido de la conversación será oculto, otros datos
            como notificaciones serán visibles para todos`,
  [k.CONVERSATIONS_WILL_BE_VISIBLE1]: `Las conversaciones solo serán visibles para los participantes, admin(s),
            y usuarios en la lista blanca`,
  [k.PRIVACY_SETTINGS_DON_T_APPLY_T]: `La configuración de privacidad no se aplica a las personas en la lista blanca`,
  [k.MAKE_PRIVATE1]: `Hacer privado`,
  [k.ONLY_PARTICIAPNTS_CAN_ACCESS_T]: `Solo los participantes pueden acceder a estos registros`,
  [k.PRIVATE_CONVERSATIONS_CAN_ONLY]: `Las conversaciones privadas solo pueden ser editadas por el Propietario del Proceso`,
  [k.AUTO_REMIND_IN]: `Recordatorio automático en`,
  [k.ALLOW_PARTICIPANTS_TO_DISABLE]: `Permitir a los participantes deshabilitar`,
  [k.REMINDER_MESSAGE]: `Mensaje de recordatorio`,
  [k.SELECT_VALUE]: `Seleccionar valor`,
  [k.ADD_AUTO_REMINDER]: `Agregar recordatorio automático`,
  [k.DESCRIPTION]: `Descripción`,
  [k.PARTICIPANTS]: `Participantes`,
  [k.OWNER]: `Propietario`,
  [k.FORM_OWNERS]: `Propietarios del formulario`,
  [k.PROCESS_OWNERS]: `Propietarios del proceso`,
  [k.TAB]: `Pestaña`,
  [k.NOTIFICATION_SETTINGS]: `Configuración de notificaciones`,
  [k.USECONDARY]: `uSecondary`,
  [k.EXIT_PROCESS_EDITOR]: `Salir del editor de procesos`,
  [k.ENTER_A_TITLE_FOR_THIS_PROCESS]: `Ingrese un título para este proceso`,
  [k.RIGHT]: `derecha`,
  [k.RED1]: `rojo.600`,
  [k.SAVEPROCESSTEMPLATEBUTTON]: `guardarBotónPlantillaProceso`,
  [k.UPRIMARY]: `uPrimary`,
  [k.DEFINE_NEW_PROCESS_TEMPLATE]: `Definir nueva plantilla de proceso`,
  [k.CREATE_PROCESS]: `Crear proceso`,
  [k.SAVE_CHANGES]: `Guardar cambios`,
  [k.TRANSPARENTLINK]: `enlaceTransparente`,
  [k.EXPORT_FIELDS_JSON]: `Exportar campos JSON`,
  [k.ADDRESS]: `dirección`,
  [k.TEMPLATEID]: `ID de plantilla`,
  [k.SAVE_AND_GO_TO]: `Guardar e ir a`,
  [k.TEAM]: `Equipo`,
  [k.STATUS]: `Estado`,
  [k.DELETED_FIELDS]: `Campos eliminados`,
  [k.ADVANCED_PROCESS_SETTINGS]: `Configuraciones avanzadas del proceso`,
  [k.DEFAULT_FORM_COLOUR]: `Color predeterminado del formulario`,
  [k.SETTINGS]: `ajustes`,
  [k.FIELDNAME]: `nombreCampo`,
  [k.DETAILS]: `Detalles`,
  [k.EXIT_FORM_EDITOR]: `Salir del editor de formularios`,
  [k.CREATE_FORM]: `Crear formulario`,
  [k.REPORTS]: `Informes`,
  [k.FORMS]: `Formularios`,
  [k._18]: `>`,
  [k.TO]: `a`,
  [k.FILTERS_APPLIED]: `Filtros aplicados:`,
  [k.SORTED_BY]: `Ordenado por:`,
  [k.CLEAR_ALL]: `Limpiar todo`,
  [k.SHARE]: `Compartir`,
  [k.RESULTS]: `Resultados`,
  [k.SAVE_AS]: `GUARDAR COMO`,
  [k.ASC]: `asc`,
  [k.ASCENDING]: `Ascendente`,
  [k.DESCENDING]: `Descendente`,
  [k.TO_ARRANGE_DRAG_UP_OR_DOWN]: `Para organizar, arrastre hacia arriba o hacia abajo`,
  [k.FILTER_NAME]: `Nombre del filtro`,
  [k.ORDER]: `Orden`,
  [k.PARAMETERS]: `Parámetros`,
  [k.NUMBER]: `número`,
  [k.SORT_BY2]: `Ordenar por`,
  [k.FILTER_VALUES]: `Valores del filtro`,
  [k.CHATROOM]: `sala de chat`,
  [k.SELECT_DATE]: `Seleccionar fecha`,
  [k.FILTER_BY]: `Filtrar por`,
  [k.AFTER]: `Después de`,
  [k.BEFORE]: `Antes de`,
  [k.FILTER]: `Filtrar`,
  [k.USERFILTER]: `Filtro de usuario`,
  [k.THERE_ARE_NO_FORMS_THAT_MATCHE]: `No hay formularios que coincidan con los filtros`,
  [k._19]: `_`,
  [k.DELETE_FORM]: `Eliminar formulario`,
  [k.FORM_NAME]: `Nombre del formulario`,
  [k.CREATED_AT]: `Creado en`,
  [k.UPDATED_AT]: `Actualizado en`,
  [k.CREATED_BY1]: `Creado por`,
  [k.DAY]: `día`,
  [k.NEW]: `NUEVO`,
  [k.MANAGE]: `Administrar`,
  [k.PROCESSES]: `Procesos`,
  [k.USER_ANALYTICS]: `análisis-de-usuario`,
  [k.USER_ANALYTICS1]: `Análisis de Usuario`,
  [k.USER_REPORTS]: `informes-de-usuario`,
  [k.FORMS1]: `formularios`,
  [k.EDIT1]: `Editar`,
  [k.DESCRIPTION1]: `Descripción:`,
  [k.REPORT]: `Informe:`,
  [k.CREATE_NEW_CHART]: `Crear nuevo gráfico`,
  [k.CHARTS_FOR_THIS_REPORT]: `Gráficos para este informe`,
  [k.COLUMN]: `columna`,
  [k.AUTO]: `automático`,
  [k.MULTIPLE_VALUE_CALCULATION]: `Cálculo de múltiples valores`,
  [k.IF_A_FIELD_CONTAINS_MULTIPLE_V]: `Si un campo contiene múltiples valores por conversación, seleccione qué
            representan los totales del eje y`,
  [k.Y_AXIS_SHOWS_TOTAL_CONVERSATIO]: `El eje y muestra el recuento total de conversaciones (es decir, peso proporcional
                entre sí)`,
  [k.Y_AXIS_SHOWS_TOTAL_VALUE_COUNT]: `El eje y muestra el recuento total de valores (es decir, peso igual entre cada valor)`,
  [k.PRIMARY_Y_AXIS_LABEL]: `Etiqueta del eje y primario`,
  [k.SECONDARY_Y_AXIS_LABEL]: `Etiqueta del eje y secundario`,
  [k.CHART_TYPE]: `Tipo de gráfico`,
  [k.PRIMARY_FIELD]: `Campo primario`,
  [k.PRIMARYRECORD]: `registroPrimario`,
  [k.PRIMARY_RECORD]: `Registro Primario`,
  [k.MULTIPLE_VALUES]: `Valores Múltiples`,
  [k.LAYOUT1]: `diseño`,
  [k.ANCHOR]: `ancla`,
  [k.ADD_COMPARISON]: `+ Añadir comparación`,
  [k.EYE]: `ojo`,
  [k.ADD_TRENDLINE]: `Añadir línea de tendencia`,
  [k.SELECT_ABOVE_FIELDS_TO_ENABLE]: `Seleccione los campos anteriores para habilitar esto`,
  [k.TOP_START]: `inicio-superior`,
  [k.TRENDLINE_LABEL]: `Etiqueta de la línea de tendencia`,
  [k.TRENDLINE_COLOR]: `Color de la línea de tendencia`,
  [k.SEQNO]: `número de secuencia`,
  [k.TITLE1]: `título`,
  [k.HIDE1]: `ocultar`,
  [k.AVG_SUM]: `promedio-suma`,
  [k.OF]: `de`,
  [k.SELECT_FIELD]: `Seleccionar campo`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET2]: `¿Estás seguro de que quieres eliminar "`,
  [k._20]: `"?`,
  [k.SERIES]: `serie`,
  [k.CHART]: `gráfico`,
  [k.GROUP_BY]: `Agrupar por`,
  [k.ADD_TREND_LINE]: `Añadir línea de tendencia`,
  [k.PERCENT]: `Porcentaje`,
  [k.PPM]: `PPM`,
  [k.SHOW_AS_PERCENT_OF_OTHER_VALUE]: `Mostrar como porcentaje del otro valor`,
  [k.RATEOPERATION]: `Operación de tasa`,
  [k.SERIES1]: `Serie #`,
  [k.SUMMARY_TYPE]: `Tipo de resumen`,
  [k.COUNT]: `conteo`,
  [k.SUM]: `suma`,
  [k.AVG]: `promedio`,
  [k.X_AXIS]: `eje x`,
  [k.UNTITLED_CHART]: `Gráfico sin título`,
  [k._21]: `45%`,
  [k._22]: `70%`,
  [k.ADVANCED_SETTINGS]: `Configuraciones avanzadas`,
  [k._23]: `-1`,
  [k.PLEASE_FILL_MANDATORY_FIELDS]: `Por favor complete los campos obligatorios`,
  [k.TOP]: `superior`,
  [k.SUBMIT]: `enviar`,
  [k.CHART1]: `Gráfico`,
  [k.SELECT_A_COMPARISON_CHART]: `Seleccionar un gráfico de comparación`,
  [k.FORM1]: `(FORM:`,
  [k.FIELD2]: `) (Campo:`,
  [k.COMMONCHECKLIST]: `lista de verificación común`,
  [k.MULTIPLE_VALUE_FIELD]: `CAMPO DE VALOR MÚLTIPLE`,
  [k.STATUS1]: `estado`,
  [k.CREATED1]: `Creado`,
  [k.COMPLETED]: `Completado`,
  [k.ADD_COLUMN]: `Añadir columna`,
  [k.SELECT_FORM1]: `Seleccionar formulario`,
  [k.THE_COUNT_WILL_REPRESENT_THE_T]: `El recuento representará el total de todas las instancias de este formulario`,
  [k.X_AXIS1]: `(eje x)`,
  [k.PRIMARY_FIELDS_ARE_THE_MAIN_UN]: `Los campos primarios son los datos subyacentes principales para un gráfico`,
  [k.GROUP_BY_FIELD]: `Campo de agrupación`,
  [k.OPTIONAL]: `(Opcional)`,
  [k.GROUP_BY_FIELD_TYPE_IS_APPLIED]: `El tipo de campo de agrupación se aplica a los campos primarios para crear un gráfico`,
  [k.ADVANCED_SETTINGS1]: `Configuraciones avanzadas`,
  [k.IF_A_FIELD_CONTAINS_MULTIPLE_V1]: `Si un campo contiene múltiples valores por conversación, seleccione qué
              representan los totales del eje y`,
  [k.Y_AXIS_LABEL]: `Etiqueta del eje y`,
  [k.SAVE_CHANGES1]: `Guardar cambios`,
  [k.CREATE_CHART]: `Crear gráfico`,
  [k.LABEL_FOR_Y_AXIS_OF_THE_CHART]: `Etiqueta para el eje y del gráfico`,
  [k.IF_A_FIELD_CONTAINS_MULTIPLE_V2]: `Si un campo contiene múltiples valores por conversación, seleccione qué
          representan los totales del eje y`,
  [k.CREATE_AND_SAVE]: `Crear y guardar`,
  [k.ON1]: `encendido`,
  [k.OFF1]: `apagado`,
  [k.Y_AXIS_SHOWS_TOTAL_CONVERSATIO1]: `El eje y muestra el recuento total de conversaciones (es decir, el peso proporcional a
          cada valor)`,
  [k.ENTER_THE_TITLE_OF_YOUR_CHART]: `Ingrese el título de su gráfico`,
  [k.NUMERIC_CHECKLIST_FIELD]: `Campo de lista de verificación numérico`,
  [k.THIS_FIELD_WILL_CONTAIN_THE_NU]: `Este campo contendrá los datos numéricos a agregar, como valores en $,
          tiempo total o unidades`,
  [k.IF_A_FIELD_CONTAINS_MULTPLE_VA]: `Si un campo contiene múltiples valores por conversación, seleccione qué
              representan los totales del eje y`,
  [k.DAILY]: `Diario`,
  [k.WEEKLY]: `Semanal`,
  [k.MONTHLY]: `Mensual`,
  [k.YEARLY]: `Anual`,
  [k.MMM_DD_YYYY1]: `MMM DD YYYY`,
  [k.LAST]: `Último`,
  [k.TIME_INTERVAL_FREQUENCY]: `Intervalo de tiempo (Frecuencia)`,
  [k.THE_INTERVAL_AT_WHICH_THE_NUME]: `El intervalo en el que se repetirá el valor numérico seleccionado anteriormente`,
  [k.TIME_PERIOD]: `Período de tiempo`,
  [k.SELECT_A_TIME_FRAME_OR_A_DATE]: `Seleccione un marco de tiempo o un rango de fechas para el gráfico`,
  [k.COLUMN1]: `Columna`,
  [k.SHOW_A_COLUMN_FOR_EACH_OF_THE]: `Muestre una columna para cada uno de los siguientes:`,
  [k.FIELDS_OF_THE_CHART]: `Campos del gráfico`,
  [k.CHART_TITLE]: `Título del gráfico`,
  [k.WHAT_DOES_THIS_CHART_REPRESENT]: `¿Qué representa este gráfico?`,
  [k.GRAY1]: `gris.600`,
  [k.SELECT_A_CHART_FOR]: `Seleccionar un gráfico para`,
  [k.SAVE_AS1]: `Guardar como`,
  [k.CLONE]: `clonar`,
  [k.DELETE1]: `eliminar`,
  [k.DO_YOU_WANT_TO_DELETE]: `¿Desea eliminar "`,
  [k.ENTER_REPORT_TITLE]: `Ingrese el título del informe`,
  [k.SAVE_AS_REPORT]: `Guardar como informe`,
  [k.EDIT_REPORT_TITLE]: `Editar título del informe`,
  [k.EDIT2]: `editar`,
  [k.REPORT_NAME]: `Nombre del informe`,
  [k.STRING1]: `Cadena`,
  [k.APPLY_BULK_CHANGES]: `Aplicar cambios masivos`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_APPLY]: `¿Está seguro de que desea aplicar cambios a múltiples conversaciones?`,
  [k.REPEAT_LAST_ACTION_TO_ALL]: `Repetir la última acción a todos`,
  [k.REPLACE_ALL]: `Reemplazar todo`,
  [k.ALL]: `Todo`,
  [k.RECORDS]: `registros`,
  [k.DATE1]: `fecha`,
  [k.CUSTOMIZE_VIEW]: `Personalizar vista`,
  [k.DOWNLOAD_AS_CSV]: `Descargar como CSV`,
  [k.DOWNLOAD_AS_XLSX]: `Descargar como XLSX`,
  [k.DOWNLOAD_WITH_FILES]: `Descargar con archivos`,
  [k.DOWNLOAD_AS_JSON_BETA]: `Descargar como JSON (beta)`,
  [k.YOU_WILL_RECEIVE_AN_EMAIL_ON]: `Recibirá un correo electrónico en`,
  [k.ONCE_THE_IMPORT_IS_DON]: `una vez que la importación esté hecha.`,
  [k.SUBMIT1]: `Enviar`,
  [k.UPLOAD_CSV]: `Subir CSV`,
  [k.SELECT_CSV_FILE]: `Seleccionar archivo CSV`,
  [k.LOADING_RESULTS]: `Cargando resultados`,
  [k.SHOW_ALL_REVISIONS]: `Mostrar todas las revisiones`,
  [k.NEW1]: `Nuevo`,
  [k.CUSTOMIZE_TABLE_VIEW]: `Personalizar vista de tabla`,
  [k.COLUMN_TITLE]: `Título de la columna`,
  [k.RESET]: `Restablecer`,
  [k.APPLY]: `Aplicar`,
  [k.SAVEAS]: `Guardar como`,
  [k.EMBEDDED_FIELDS1]: `Campos incrustados`,
  [k.EXPANSION]: `expansión`,
  [k.FILTER_BY_VALUE]: `Filtrar por valor`,
  [k.MONTHS]: `Meses`,
  [k.YEARS]: `Años`,
  [k.DATE_RANGE]: `Rango de fechas`,
  [k.FORM2]: `Formulario`,
  [k.EXPAND]: `Expandir`,
  [k.PRIORITY]: `Prioridad`,
  [k.LOW]: `Bajo`,
  [k.NORMAL1]: `Normal`,
  [k.HIGH]: `Alto`,
  [k.CRITICAL]: `Crítico`,
  [k.SELECT_ALL1]: `Seleccionar todo`,
  [k.SELECT_NONE]: `No seleccionar ninguno`,
  [k.MAPPED_TO]: `Mapeado a`,
  [k.PENDING]: `Pendiente`,
  [k.COMPLETE]: `Completo`,
  [k.INCLUDE]: `Incluir`,
  [k.ARCHIVED]: `Archivado`,
  [k.CANCELLED]: `Cancelado`,
  [k.THERE_ARE_NO_PROCESSES_THAT_MA]: `No hay procesos que coincidan con estos filtros`,
  [k.RESIZING]: `Redimensionando "`,
  [k._24]: "",
  [k.NO_VALUE]: `Sin valor`,
  [k.CURRENTCHATROOMTITLE]: `Título de la sala de chat actual`,
  [k.TEXT]: `Texto`,
  [k._25]: `90%`,
  [k._26]: `80%`,
  [k._27]: `60%`,
  [k.C]: `C`,
  [k.CARETUP]: `caretUp`,
  [k.MAKE_AS_CURRENT]: `Hacer como actual`,
  [k.ARRAY]: `Array`,
  [k.EDITPENCIL]: `Lápiz de edición`,
  [k.NO_DESCRIPTION]: `Sin descripción`,
  [k.DISABLED1]: `Deshabilitado`,
  [k.PARENT3]: `PADRE`,
  [k.NO_PARENT]: `Sin padre`,
  [k.UPDATE_STATUS]: `ACTUALIZAR ESTADO:`,
  [k.UNTITLED_CONVERSATION]: `Conversación sin título`,
  [k.PAGE]: `Página`,
  [k.GO_TO_PAGE]: `| Ir a la página:`,
  [k.BACK_TO_REPORT]: `Volver al informe`,
  [k.CREATE_ANOTHER_CHART]: `Crear otro gráfico`,
  [k.OWNER1]: `propietario`,
  [k.DUEDATE]: `fecha de vencimiento`,
  [k._28]: `↑`,
  [k._29]: `↓`,
  [k.PROCESS_NAME]: `Nombre del proceso`,
  [k.ACTIVE_INSTANCES]: `Registros activos`,
  [k.TOTAL_INSTANCES]: `Registros totales`,
  [k.CREATED_BY2]: `Creado por`,
  [k.EDIT_PROCESS]: `Editar proceso`,
  [k.DELETE_PROCESS]: `Eliminar proceso`,
  [k.LOCK]: `Bloquear`,
  [k.DRAFT]: `BORRADOR`,
  [k.DELETE_PROCESS1]: `Eliminar proceso`,
  [k.ALL_ASSOCIATED_CONVERSATIONS]: `? Se cancelarán todos los registros asociados.`,
  [k.FETCHING_USER_AND_ORG_DETAILS]: `Obteniendo detalles del usuario y la organización`,
  [k.INTEGRATION_NOT_FOUND]: `¡Integración no encontrada!`,
  [k.LOGO]: `-logo`,
  [k.IS_REQUESTING_PERM]: `está solicitando
            permiso para acceder a su cuenta de Unifize en`,
  [k.WHAT_CAN]: `¿Qué puede`,
  [k.DO]: `hacer?`,
  [k.ALLOW]: `Permitir`,
  [k.DENY]: `Denegar`,
  [k.HTTPS_CDN_BFLDR_COM_H_O_W]: `https://cdn.bfldr.com/5H442O3W/at/pl546j-7le8zk-6gwiyo/Slack_Mark.svg?auto=webp&format=svg`,
  [k.SLACK]: `Slack`,
  [k.MICROSOFT]: `Microsoft 365`,
  [k.MICROSOFT_ADMIN]: `Administrador de Microsoft 365`,
  [k.CREATE_CONVERSATIONS_ON_UNIFIZ]: `Crear conversaciones en Unifize en su nombre.`,
  [k.FORWARD_MESSAGES_TO_UNIFIZE_ON]: `Reenviar mensajes a Unifize en tu nombre.`,
  [k.NETWORK_ERROR_LOOKS_LIKE_YOU]: `¡Error de red! Parece que estás sin conexión :(`,
  [k.CLICK_HERE_TO_TRY_AGAIN]: `Haz clic aquí para intentarlo de nuevo`,
  [k.EMAIL1]: `Correo electrónico`,
  [k.CREATING]: `Creando`,
  [k.ORG1]: `Org...`,
  [k.YOUR_ORG_IS_READY_YOU_WILL_BE]: `Tu organización está lista. Serás redirigido en breve.`,
  [k.ERROR_CREATING_YOUR_ORG]: `Error al crear tu organización`,
  [k.SOMEONE_FROM_UNIFIZE_WILL_SOON]: `Alguien de Unifize pronto se pondrá en contacto contigo.`,
  [k.ORG_NAME]: `Nombre de la organización`,
  [k.EMAIL_ADDRESS]: `Dirección de correo electrónico`,
  [k.AT_LEAST_CHARACTERS]: `Al menos 8 caracteres`,
  [k.A_MIXTURE_OF_LETTERS_AND_NUMBE]: `Una mezcla de letras y números.`,
  [k.INCLUSION_OF_AT_LEAST_ONE_UPPE]: `Inclusión de al menos una letra mayúscula`,
  [k.CONFIRM_PASSWORD]: `Confirmar contraseña`,
  [k.FIRST_NAME]: `Nombre`,
  [k.LAST_NAME]: `Apellido`,
  [k.PLEASE_VERIFY_YOUR_EMAIL_ADDRE]: `Por favor, verifica tu dirección de correo electrónico...`,
  [k.CLICK_ON_THE_LINK_WE_SENT_TO]: `Haz clic en el enlace que te enviamos a`,
  [k.TO_CREATE_YOUR_ORG]: `para crear tu organización.`,
  [k.DIDN_T_RECEIVE_AN_EMAIL]: `¿No recibiste un correo electrónico?`,
  [k.RESEND_EMAIL]: `Reenviar correo electrónico`,
  [k.STEP_OF]: `Paso 1 de 3`,
  [k.SIGN_UP]: `Registrarse`,
  [k.SPEED_UP_YOUR_PROCESSES_BY_X]: `Acelera tus procesos en 3x en 30 días`,
  [k.STEP_OF1]: `Paso 2 de 3`,
  [k.CREATE_ACCOUNT]: `Crear cuenta`,
  [k.SIGN_IN]: `Iniciar sesión`,
  [k.YOU_HAVE_ALREADY_USED_THIS_EMA]: `Ya has utilizado este correo electrónico para crear una cuenta.`,
  [k.STEP_OF2]: `Paso 3 de 3`,
  [k.SIGN_OUT]: `Cerrar sesión`,
  [k.LAST_STEP]: `Último paso`,
  [k.INVITE_YOUR_COLLABORATORS]: `, Invita a tus colaboradores`,
  [k.NO_MORE_SILOS_INVITE_UP_TO]: `No más silos. Invita hasta 4 colegas ahora. Puedes invitar a más
            más tarde.`,
  [k.STAGE_INFO_HEADER]: `Encabezado de información de la etapa:`,
  [k.STAGE]: `Etapa:`,
  [k.THIS_LINK_IS_NOT_VALID]: `Este enlace no es válido`,
  [k.NEXT]: `Siguiente`,
  [k.BY_SIGNING_UP_I_AGREE_TO_THE]: `Al registrarme, acepto la Política de privacidad y los Términos de
              Servicio de Unifize.`,
  [k._30]: `30%`,
  [k.CENTER_SECTION]: `Sección central`,
  [k.RIGHT_SECTION]: `Sección derecha`,
  [k.ADD_SECTION]: `+ Añadir sección`,
  [k.SELECT_FROM_EXISTING_TILES]: `Seleccionar de los mosaicos existentes`,
  [k.CREATE_NEW_TILE]: `Crear nuevo mosaico`,
  [k.CONVERSATIONS]: `Conversaciones`,
  [k.MY_CONVERSATION_CHART]: `Mi gráfico de conversación`,
  [k.START_NEW]: `Comenzar nuevo`,
  [k.PIN_A_DASHBOARD_CHART]: `Fijar un gráfico de dashboard`,
  [k.NAME2]: `NOMBRE`,
  [k.DO_YOU_WANT_TO_DELETE1]: `¿Quieres eliminar`,
  [k.ADD_NEW_TILE]: `+ Añadir nuevo mosaico`,
  [k.SUB_TITLE]: `Subtítulo`,
  [k.FILTERS]: `Filtros`,
  [k.SELECT_AT_LEAST_ONE]: `(Selecciona al menos uno)`,
  [k.UNREAD]: `No leído`,
  [k.I_OWN]: `Soy el propietario`,
  [k.BLUECROSS]: `Cruz azul`,
  [k.EVERYONE]: `Todos`,
  [k.EVERYONE1]: `Todos`,
  [k.BUTTON_TEXT]: `Texto del botón`,
  [k.WHEN_EMPTY]: `Cuando esté vacío`,
  [k.TILE_COLOR]: `Color del mosaico`,
  [k.ALL1]: `Todos`,
  [k.SELECT_A_CHART]: `Seleccionar un gráfico`,
  [k.DON_T_SHOW_TILE]: `No mostrar el mosaico`,
  [k.SHOW_A_MESSAGE]: `Mostrar un mensaje`,
  [k.EXCLUDE_PROCESS]: `Excluir proceso`,
  [k.INCLUDE_PROCESS]: `Incluir proceso`,
  [k.COLUMNS]: `Columnas`,
  [k.OVERDUE]: `Atrasado`,
  [k.TILE_TYPE_CONVERSATIONS]: `Tipo de mosaico: Conversaciones`,
  [k.TILE_TYPE_MY_CONVERSATION_CHA]: `Tipo de mosaico: Mi gráfico de conversación`,
  [k.TILE_TYPE_START_NEW]: `Tipo de mosaico: Comenzar nuevo`,
  [k.TILE_TYPE_DASHBOARD_CHART]: `Tipo de mosaico: Gráfico de dashboard`,
  [k.SECTION1]: `Sección:`,
  [k.ADD_USER_SEGMENT]: `+ Añadir segmento de usuario`,
  [k.AND_USER]: `Y usuario`,
  [k.SHOW_TO]: `Mostrar a`,
  [k.AND_USER1]: `Y usuario`,
  [k.AGE1]: `Edad`,
  [k.SELECT_TIMEPERIOD]: `Seleccionar período de tiempo`,
  [k.SELECTED]: `Seleccionado`,
  [k.SELECT_GROUP]: `Seleccionar grupo`,
  [k.SELECT_VALUES]: `Seleccionar valores`,
  [k.SEGMENT]: `Segmento`,
  [k.CREATE_NEW_USER_SEGMENT]: `Crear nuevo segmento de usuario`,
  [k.OTHERS1]: `Otros`,
  [k.ENABLE_HOME_SCREEN]: `Habilitar pantalla de inicio`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DISAB]: `¿Estás seguro de que quieres deshabilitar`,
  [k.INTEGRATION]: `integración?`,
  [k.DISABLE]: `Deshabilitar`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DISCO]: `¿Estás seguro de que quieres desconectar la`,
  [k.INTEGRATION_YOU_COULD]: `integración? Podrías
          perder datos a los que se accede o aloja a través de`,
  [k.YOU_CANNOT_UNDO_THIS_ACTION_C]: `No puedes deshacer esta acción. Considera deshabilitar la integración
          en su lugar.`,
  [k.DISCONNECT]: `Desconectar`,
  [k.HEADING]: `encabezado`,
  [k.INTEGRATION1]: `Integración`,
  [k.INTEGRATE]: `Integrar`,
  [k.GLOBAL_OR_PRIVILEGED_ADMINIST]: `*Administradores globales o privilegiados solamente`,
  [k.NO_APPS_FOUND]: `No se encontraron aplicaciones`,
  [k.ORG_SETTINGS]: `Configuraciones de la organización`,
  [k.HOME_SCREEN]: `Pantalla de inicio`,
  [k.ORG_DETAILS]: `Detalles de la organización`,
  [k.ROLE_MANAGEMENT]: `Gestión de roles`,
  [k.INTEGRATIONS]: `Integraciones`,
  [k.SSO]: `SSO`,
  [k.HOME_SCREEN1]: `Pantalla de inicio`,
  [k.ORG_DETAILS1]: `Detalles de la organización`,
  [k.ROLE_MANAGEMENT1]: `Gestión de roles`,
  [k.APPS]: `Aplicaciones`,
  [k.SSO1]: `SSO`,
  [k.DELETE_ROLE]: `Eliminar rol`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET3]: `¿Estás seguro de que quieres eliminar este rol?`,
  [k.DELETE_ORG_DOMAIN]: `Eliminar dominio de la organización`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET4]: `¿Estás seguro de que quieres eliminar este dominio de la organización?`,
  [k.ORG_DOMAIN]: `Dominio de la organización`,
  [k.EMAIL_PREFIX]: `Prefijo de correo electrónico`,
  [k.ALLOW_CLONING]: `Permitir clonación`,
  [k.ORG_DESCRIPTION]: `Descripción de la organización`,
  [k.ORG_DESCRIBER_VIDEO_PASTE_IFR]: `Video de descripción de la organización (Pegar código iframe)`,
  [k.ADD_USER]: `Añadir usuario`,
  [k.MEMBERS1]: `MIEMBROS`,
  [k.ADD_USERS]: `+ Añadir usuarios`,
  [k.ADD_A_NEW_ROLE]: `+ Añadir un nuevo rol`,
  [k.FUNCTIONALITY]: `Funcionalidad`,
  [k.DOMAIN_TAG]: `etiqueta-de-dominio-`,
  [k.DOMAIN]: `dominio-`,
  [k.SERVICE_PROVIDER_ENTITY_ID]: `ID de entidad del proveedor de servicios`,
  [k.AUTHORIZATION_CALLBACK_URL]: `URL de devolución de llamada de autorización`,
  [k.HUGE_PARAGRAPH_HERE]: `(Párrafo enorme aquí)`,
  [k.YES_LOGOUT_ALL_USERS]: `Sí, cerrar sesión de todos los usuarios`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DISAB1]: `¿Estás seguro de que quieres deshabilitar el SSO?`,
  [k.ENTER_NEW_PASSWORD]: `Introduce una nueva contraseña`,
  [k.CONFIRM_NEW_PASSWORD]: `Confirma la nueva contraseña`,
  [k.DISABLE_SSO]: `Deshabilitar SSO`,
  [k.DOMAIN1]: `Dominio`,
  [k._31]: `*`,
  [k.ENTITY_ID]: `ID de entidad`,
  [k.SSO_URL]: `URL de SSO`,
  [k.CERTIFICATE]: `Certificado`,
  [k.ADD_CERTIFICATE]: `Añadir certificado`,
  [k.MUST_START_WITH_BEGIN_CE]: `Debe comenzar con '-----BEGIN CERTIFICATE-----' y terminar con '-----END CERTIFICATE-----'`,
  [k.PAGE_NOT_FOUND]: `404 Página no encontrada`,
  [k.WELCOME_BACK1]: `¡Bienvenido de nuevo!`,
  [k._32]: `!`,
  [k.LOG_IN]: `Iniciar sesión`,
  [k.TO_USE_THE_FULL]: `para usar la versión completa de la aplicación`,
  [k.BLANK]: `_blank`,
  [k.LOG_IN1]: `Iniciar sesión`,
  [k.SIGN_UP1]: `Registrarse`,
  [k.DRIVE_PROCESS_THROUGH_CONVERSA]: `Impulsar el proceso a través de la conversación`,
  [k.UNIFIZE_INCREASES_YOUR_TEAM]: `Unifize aumenta tu equipo`,
  [k._33]: `'`,
  [k.S_EFFICIENCY_BY_UP_TO_X_BY]: `la eficiencia en hasta 10x al
            conectar sus conversaciones con los objetivos del proceso.`,
  [k.SIGN_UP_FAILED]: `¡La inscripción falló!`,
  [k.CLICK_HERE_TO]: `Haz clic aquí para`,
  [k.RESEND1]: `reenviar`,
  [k.VERIFICATION_EMAIL_SENT]: `Correo de verificación enviado`,
  [k.AN_EMAIL_HAS_BEEN_SENT_TO]: `Se ha enviado un correo electrónico a`,
  [k.PLEASE_OPEN_YOUR_EMA]: `. Por favor, abre
        tu correo electrónico y haz clic en el enlace para configurarte en Unifize.`,
  [k.OR]: `O`,
  [k.MENU]: `menú`,
  [k.CHATROOM_HEADER]: `Encabezado de la sala de chat`,
  [k.CLICK_HERE_TO_SIGN_UP_OR_LOG_I]: `Haz clic aquí para registrarte o iniciar sesión.`,
  [k.HEADER]: `encabezado`,
  [k.MOBILEHISTORY]: `historial móvil`,
  [k.INPUT]: `entrada`,
  [k.YOU_NEED_TO_LOG_IN_TO_DO_THAT]: `Necesitas iniciar sesión para hacer eso`,
  [k.HISTORY]: `historial`,
  [k.PANEL]: `panel`,
  [k.PLUSCIRCLE]: `plusCircle`,
  [k.FILE]: `archivo`,
  [k.MANAGE_FILE_VIEW]: `administrar-vista-de-archivo`,
  [k.CREATEPROCESSTEMPLATE]: `crearPlantillaDeProceso`,
  [k.REMOVECIRCLE]: `removeCircle`,
  [k.CLEAR_ALL1]: `Borrar todo`,
  [k.OPTION_NOT_FOUND]: `Opción no encontrada`,
  [k.ADD_NEW_OPTION]: `Añadir nueva opción`,
  [k.NEW_OPTION]: `Nueva opción`,
  [k.EMAIL_NOTIFICATIONS]: `Notificaciones por correo electrónico`,
  [k.SELECT_YOUR_PREFERED_NOTIFICAT]: `Selecciona tu configuración de notificación preferida`,
  [k.NOTIFICATIONS]: `Notificaciones`,
  [k.EVERYTHING]: `Todo:`,
  [k.ALL_UPDATES_AND_MESSAGES]: `Todas las actualizaciones y mensajes`,
  [k.FOR_CONVERSATIONS_YOU_ARE_A_PA]: `Para las conversaciones en las que participas`,
  [k.YOU_WILL_BE_NOTI]: `, serás
            notificado por correo electrónico de todas estas actualizaciones:`,
  [k.ALL_COMMENTS]: `Todos los comentarios`,
  [k.AND_MESSAGES]: `y mensajes`,
  [k.YOU_VE_BEEN_ADDED]: `te han añadido`,
  [k.TO_A_CONVERSATION]: `a una conversación`,
  [k.ALL_STATUS_UPDATES]: `Todas las actualizaciones de estado,`,
  [k.INCLUDING_WHEN_CONVERSATIONS]: `incluyendo cuando las conversaciones
              se completan`,
  [k.WHEN_THE_CONVERSATION_IS]: `Cuando la conversación está`,
  [k.OVERDUE1]: `atrasada`,
  [k.AND_OTHER]: `y otros`,
  [k.ANY_OWNER_CHANGE]: `Cualquier cambio de propietario`,
  [k.OR_ASSIGNMENT]: `o asignación`,
  [k.WHEN_YOU_VE_BEEN]: `Cuando has sido`,
  [k.MENTIONED]: `mencionado`,
  [k.IN_A_CONVERSATION]: `en una conversación`,
  [k.IMPORTANT]: `Importante:`,
  [k.STATUS_UPDATES_REMINDERS_AND]: `Actualizaciones de estado, recordatorios y @menciones`,
  [k.FOR_CONVERSATIONS_YOU_ARE_A_PA1]: `Para las conversaciones en las que participas,`,
  [k.YOU_WILL_BE_NOTIFI]: `serás
            notificado por correo electrónico de todas estas actualizaciones:`,
  [k.ALL_STATUS_UPDATES1]: `Todas las actualizaciones de estado`,
  [k.INCLUDING_WHEN_CONVERSATIONS1]: `, incluyendo cuando las conversaciones
              se completan`,
  [k.AND_OTHER_REMIND]: `y otros recordatorios`,
  [k.CRITICAL1]: `Crítico:`,
  [k.CRITICAL_UPDATES_AND_MENTIONS]: `Actualizaciones críticas y @menciones`,
  [k.YOU_VE_BEEN_ASSIGNED_AS_THE_OW]: `Has sido asignado como el propietario`,
  [k.OF_A_CONVERSATIO]: `de una conversación`,
  [k.YOU_VE_BEEN]: `Has sido`,
  [k.NOTHING_NOT_RECOMMENDED]: `Nada (No recomendado)`,
  [k.YOU_WILL_NOT_BE_NOTIFIED_VIA_E]: `No serás notificado por correo electrónico. Esto puede resultar en que te pierdas
            conversaciones muy importantes de las que eres parte.`,
  [k.SORT]: `Ordenar`,
  [k.FILTER_VALUES1]: `Filtrar valores`,
  [k.SEARCH1]: `buscar`,
  [k.BLUETICK]: `marcaAzul`,
  [k.CREATE_NEW_STATUS]: `+ Crear nuevo estado`,
  [k.ENTER_STATUS_TITLE]: `Ingresa el título del estado`,
  [k.ACTIVERADIOBUTTON]: `botonRadioActivo`,
  [k.DEFAULTRADIOBUTTON]: `botonRadioPorDefecto`,
  [k.MAP_TO_PENDING]: `Mapear a Pendiente`,
  [k.MAP_TO_COMPLETE]: `Mapear a Completado`,
  [k.DISABLE_UNTIL_APPROVED]: `Desactivar hasta aprobado`,
  [k.DELETETRASH]: `eliminarPapelera`,
  [k.CIRCLEDOWN]: `circuloAbajo`,
  [k.HIDE_UNTIL_CONDITION_MET]: `Ocultar hasta que se cumpla la condición`,
  [k.ENABLE_UNTIL_CONDITION_MET]: `Habilitar hasta que se cumpla la condición`,
  [k.SHOWN]: `mostrado`,
  [k.STATUS_TYPE]: `Tipo de estado`,
  [k.PENDING1]: `pendiente`,
  [k.COMPLETE1]: `completado`,
  [k.ARCHIVE_BEHAVIOR]: `Comportamiento de archivo`,
  [k.IGNORE_ARCHIVING]: `Ignorar el archivado`,
  [k.AUTO_UNARCHIVE]: `Desarchivar automáticamente`,
  [k.STATUS_SETTINGS]: `Configuraciones de estado`,
  [k.YOU_NEED_TO_HAVE_AT_LEAST_ONE]: `Necesitas tener al menos un estado mapeado a`,
  [k.PENDING2]: `PENDIENTE`,
  [k.AND]: `y`,
  [k.COMPLETED1]: `COMPLETADO`,
  [k.EACH]: `cada uno.`,
  [k.OK]: `OK`,
  [k.ACTIVECHECKBOX]: `casillaActiva`,
  [k.DEFAULTCHECKBOX]: `casillaPorDefecto`,
  [k.SELECTED_ITEM]: `elementoSeleccionado-`,
  [k._34]: `✕`,
  [k.UPDATE_HAS_BEEN_RELASED]: `La actualización ha sido lanzada`,
  [k.THERE_IS_A_NEW_VERSION_OF_THE]: `Hay una nueva versión de la aplicación disponible, haz clic en actualizar para verla.`,
  [k.ACCESS_DENIED]: `Acceso denegado!!`,
  [k.CONTACT_US]: `Contáctanos`,
  [k.LOOKS_LIKE_YOUR_EMAIL_IS_NOT_V]: `Parece que tu correo electrónico no está verificado`,
  [k.AN_EMAIL_HAS_BEEN_SENT_TO_YOUR]: `Se ha enviado un correo electrónico a tu cuenta. Por favor, abre tu correo electrónico y haz clic
      en el enlace para configurarte en Unifize.`,
  [k.STILL_HAVING_TROUBLES]: `¿Todavía tienes problemas?`,
  [k.FORGOT_YOUR_PASSWORD]: `¿Olvidaste tu contraseña?`,
  [k.ENTER_THE_EMAIL_ADDRESS_YOU_US]: `Ingresa la dirección de correo electrónico que usaste para esta cuenta. Recibirás
            un correo electrónico en unos minutos con un enlace para restablecer tu contraseña.`,
  [k.SEND_LINK]: `Enviar enlace`,
  [k.PASSWORD1]: `contraseña`,
  [k.LOADING1]: `cargando`,
  [k.LOADING_CONVERSATIONS_FOR]: `Cargando conversaciones para`,
  [k.UNABLE_TO_LOGIN]: `No se puede iniciar sesión -`,
  [k.CONTINUE_IN_APP]: `Continuar en la aplicación`,
  [k.HEY_THERE_LOOKS_LIKE_YOU_RE_T]: `¡Hola! Parece que estás intentando iniciar sesión desde un dispositivo móvil.`,
  [k.CONTINUE_WITH_UNIFIZE_MOBILE_A]: `Continuar con la aplicación móvil de Unifize`,
  [k.ABOUT_US]: `Sobre nosotros`,
  [k.RESET_YOUR_PASSWORD]: `Restablecer tu contraseña`,
  [k.YOU_HAVE_CLICKED_ON_A_PASSWORD]: `Has hecho clic en un enlace de restablecimiento de contraseña para:`,
  [k.ENTER_A_NEW_PASSWORD]: `Ingresa una nueva contraseña`,
  [k.RESET_MY_PASSWORD]: `Restablecer mi contraseña`,
  [k.PASSWORD_RESET]: `Contraseña restablecida`,
  [k.RESET_LINK_SENT]: `Enlace de restablecimiento enviado`,
  [k.RESET_INSTRUCTIONS_HAVE_BEEN_S]: `Se han enviado instrucciones de restablecimiento a`,
  [k.YOU_WILL_RECEIVE]: `. Recibirás
            el correo electrónico dentro de 5 minutos. El correo electrónico podría estar oculto
            en tu carpeta de spam.`,
  [k.RESENT_INSTRUCTIONS]: `Reenviar instrucciones`,
  [k.PASSWORD_CHANGE_SUCCESSFUL]: `Cambio de contraseña exitoso`,
  [k.YOUR_PASSWORD_HAS_BEEN_RESET_S]: `Tu contraseña ha sido restablecida con éxito para:`,
  [k.CONTINUE_TO_YOUR_UNIFIZE_APP]: `Continuar en tu aplicación de Unifize`,
  [k.SERVER_ERROR]: `Error del servidor`,
  [k.RELOAD]: `Recargar`,
  [k.LOGOUT]: `Cerrar sesión`,
  [k.XL]: `2xl`,
  [k.FORGOT_PASSWORD]: `¿Olvidaste tu contraseña?`,
  [k.DON]: `No`,
  [k.T_HAVE_AN_ACCOUNT]: `¿No tienes una cuenta?`,
  [k.SIGN_UP_NOW]: `¡Regístrate ahora!`,
  [k.THIS_EMAIL_ADDRESS_IS_NOT_VERI]: `Esta dirección de correo electrónico no está verificada`,
  [k.THE_USERNAME_OR_PASSWORD_IS_IN]: `El nombre de usuario o la contraseña es incorrecta`,
  [k.TOO_MANY_FAILED_LOGIN_ATTEMPTS]: `Demasiados intentos de inicio de sesión fallidos, inténtalo de nuevo más tarde`,
  [k.SOMETHING_WENT_WRONG_TRY_AGAI]: `Algo salió mal, inténtalo de nuevo más tarde`,
  [k.PLEASE_CHECK_YOUR_INBOX]: `Por favor, revisa tu bandeja de entrada`,
  [k.VERIFYING_YOUR_LINK]: `Verificando tu enlace`,
  [k.NO_CHARTS_FOUND]: `No se encontraron gráficos`,
  [k.REFRESH1]: `actualizar`,
  [k.SELECT_FIELD1]: `Seleccionar campo`,
  [k.TASKLIST]: `lista de tareas`,
  [k.MANDATORY1]: `Obligatorio`,
  [k.MANDATORY_FIELD_REMAINING]: `Campo obligatorio restante`,
  [k.RELOAD1]: `recargar`,
  [k.OPEN_CHECKLIST]: `Abrir lista de verificación`,
  [k.ROTATE_DEG]: `rotar(-180deg)`,
  [k.TRANSFORM_S_EASE]: `transformar 0.3s facilidad`,
  [k.CARETLEFTFILLED]: `caretIzquierdaLleno`,
  [k.DEEAEE]: `#DEEAEE`,
  [k.EAB]: `#919EAB`,
  [k.CLICK_TO_ADD_DESCRIPTION]: `Haz clic para agregar descripción`,
  [k.DIRECT]: `directo`,
  [k.EM2]: `1.25em`,
  [k.VIEW_IN_ACTIVE_CHAT]: `Ver en la bandeja de entrada`,
  [k.UNABLE_TO_PERFORM_ACTION]: `No se puede realizar la acción`,
  [k.YOU_SHOULD_NAVIGATE_TO_CONVERS]: `Deberías navegar a la conversación para realizar esta acción.`,
  [k.TO_PERFORM_THIS_ACTION]: `para realizar esta acción`,
  [k.CONVERSATION_DIALOG]: `diálogo de conversación`,
  [k.CONVERSATION_DIALOG1]: `#diálogo de conversación`,
  [k.DATEACTIVE]: `fechaActiva2`,
  [k.DATEACTIVE1]: `fechaActiva`,
  [k.DUEDATE1]: `fechaVencimiento-`,
  [k.ACTIVITY_PANEL]: `panel de actividad`,
  [k.FILES_FROM_UNIFIZE_CONVERSATIO]: `Archivos de las conversaciones de Unifize`,
  [k.ATTACH_FILES_FROM_YOUR_COMPUTE]: `Adjuntar archivos desde tu computadora`,
  [k.CC]: `Cc`,
  [k.BCC]: `Cco`,
  [k.SUBJECT]: `Asunto`,
  [k.UNIFIZE_GREY]: `grisUnifize4`,
  [k.JUSTIFY]: `justificar`,
  [k.SEND_AS_EMAIL]: `Enviar como correo electrónico`,
  [k.DISCARD]: `Descartar`,
  [k.SEND]: `Enviar`,
  [k.FILES_IN_CHATROOM]: `Archivos en la sala de chat`,
  [k.ACCESS_REQUEST]: `Solicitud de acceso`,
  [k.PEOPLE_HAVE_REQUESTED_TO_ACCES]: `personas han solicitado acceso a esta conversación privada`,
  [k.ACCEPT]: `Aceptar`,
  [k.PEOPLE]: `personas`,
  [k.HAVE_REQUESTED_TO]: `han solicitado`,
  [k.RESPOND]: `Responder`,
  [k.HAS_REQUESTED_TO_JOIN]: `ha solicitado unirse a la conversación`,
  [k.FAVOURITE]: `favorito`,
  [k._35]: `;`,
  [k.CHANGE_PROCESS_TYPE]: `Cambiar tipo de proceso`,
  [k.TYPE2]: `tipo-`,
  [k.DAYS_OLD]: `días de antigüedad`,
  [k.LESS_THAN_A_DAY_OLD]: `Menos de un día de antigüedad`,
  [k.LINK_COPIED]: `Enlace copiado`,
  [k.CONVERSATION_LINK_HAS_BEEN_COP]: `El enlace del registro ha sido copiado`,
  [k.CONVERSATION_LINK]: `Enlace de la conversación`,
  [k.DOWNLOAD_JSON]: `Descargar JSON`,
  [k.FILES1]: `Archivos (`,
  [k.NO_RESULTS_FOR]: `No hay resultados para`,
  [k.EMAIL_ADDRESS_COPIED]: `Dirección de correo electrónico copiada`,
  [k.USE_COPIED_EMAIL_ADDRESS_TO_FO]: `Utilice una dirección de correo electrónico copiada para reenviar su correo a este registro`,
  [k.FORWARD_MAIL_HERE]: `Reenviar correo aquí`,
  [k.PRIVACY]: `Privacidad`,
  [k.HIDE_EVERYTHING1]: `(Ocultar todo)`,
  [k.HIDE_ONLY_CHAT_CONTENT1]: `(Ocultar solo el contenido del chat)`,
  [k.PROCESS_BUILDER]: `Constructor de procesos`,
  [k.RELATED_CONVERSATIONS]: `Registros relacionados`,
  [k.MOREMENUFILLED]: `másMenuLleno`,
  [k.MOREMENU]: `másMenu`,
  [k.MORE2]: `Más`,
  [k.V]: `V`,
  [k.REVISIONS1]: `Revisiones`,
  [k._36]: `...`,
  [k.CHAT_INPUT]: `entrada de chat`,
  [k.PLUS]: `más`,
  [k.PLUSCHATICON]: `íconoChatMás`,
  [k.D]: `d`,
  [k.FORWARD_TO]: `Reenviar a`,
  [k.FORWARD]: `Reenviar`,
  [k.UPLOAD_IMAGE]: `Subir imagen`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_UPLOA]: `¿Estás seguro de que quieres subir esta imagen?`,
  [k.OWNER2]: `PROPIETARIO`,
  [k.SELECTED1]: `Seleccionado`,
  [k.RECENT]: `reciente`,
  [k.ADD_LINK]: `Agregar enlace`,
  [k.ENTER_VALID_URL]: `Ingresa una URL válida`,
  [k.MENTIONBUTTON]: `botónMención`,
  [k.NO_RESULTS_FOUND]: `No se encontraron resultados`,
  [k.EVERY_PARTICIPANT_OF_THE_CONV]: `(cada participante de la conversación)`,
  [k.CURRENT_OWNER_OF_THE_CONVERSA]: `(propietario actual de la conversación)`,
  [k.CREATOR_OF_THE_CONVERSATION]: `(creador de la conversación)`,
  [k.LOADING_MESSAGES]: `Cargando mensajes...`,
  [k.JOIN]: `Unirse`,
  [k.FULL_NAME_OPTIONAL]: `Nombre completo (opcional)`,
  [k.EMAIL_ADDRESS1]: `Dirección de correo electrónico`,
  [k.SELECT_ROLE]: `Seleccionar rol`,
  [k.SEND_INVITE]: `Enviar invitación`,
  [k.ADD_PARTICIPANTS_OR_GROUPS]: `Agregar participantes o grupos`,
  [k.NAME_EMAIL_OR_GROUP]: `NOMBRE, EMAIL O GRUPO`,
  [k.INVITE]: `Invitar "`,
  [k.NO_RESULTS_FOR1]: `No hay resultados para "`,
  [k.TO_INVITE_SOMEONE_EXTERNALLY]: `Para invitar a alguien externamente, por favor usa su dirección de correo electrónico. ej.`,
  [k.JOHN_DOE_ACME_COM]: `john.doe@acme.com`,
  [k.TO_CONVERSATION]: `registrar`,
  [k.SEND_INVITES]: `Enviar invitaciones`,
  [k.PARTICIPANTS1]: `PARTICIPANTES`,
  [k.ADD_PARTICIPANTS_OR_GROUPS1]: `+ Agregar participantes o grupos`,
  [k.PARTICIPANT]: `Participante`,
  [k.CURVE]: `curva`,
  [k.DELETED_USER]: `Usuario eliminado`,
  [k.CHAT_WITH]: `Chatear con`,
  [k.START_RELATED_CONVERSATION]: `Iniciar conversación relacionada`,
  [k.OTHER]: `Otro`,
  [k.ADD_TO_CONVERSATION]: `Agregar a la conversación`,
  [k.MEMBER1]: `Miembro`,
  [k.UPLOAD_FILES]: `Subir archivos`,
  [k.FROM_PRIVATE_CONVERSATION]: `desde conversación privada`,
  [k.START_PROCESS]: `Iniciar proceso`,
  [k.REMOVE_OWNER]: `Eliminar propietario`,
  [k.INVITE1]: `Invitar`,
  [k.INVITE_VIA_EMAIL]: `Invitar por correo electrónico`,
  [k.ASSIGN_OWNER]: `Asignar propietario`,
  [k.FIT_CONTENT]: `Ajustar contenido`,
  [k.SOLID]: `sólido`,
  [k.NOOWNERACTIVE]: `sinPropietarioActivo`,
  [k.NOOWNER]: `sinPropietario`,
  [k.ASSIGN_OWNER1]: `Asignar propietario`,
  [k.NO_OWNER]: `Sin propietario`,
  [k.MESSAGE]: `mensaje`,
  [k.SET_PRIORITY]: `Establecer prioridad`,
  [k.SET_DUE_DATE]: `Fecha de vencimiento`,
  [k.SET_PRIORITY_FOR_CONVERSATION]: `Establecer prioridad para este registro`,
  [k.BOLD]: `negrita`,
  [k.GRAY2]: `gris.200`,
  [k.PRIORITY1]: `prioridad-`,
  [k.PRIORITY2]: `Prioridad:`,
  [k.FAILED_TO_UPLOAD]: `Error al subir`,
  [k.UPLOADING]: `Subiendo`,
  [k._37]: `%`,
  [k.RESTRICTED_ACCESS]: `Acceso restringido`,
  [k.THE_CONVERSATION_YOU_ARE_TRYIN]: `La conversación a la que estás intentando acceder no está abierta para tu
            cuenta. Contacta al administrador de tu organización para obtener acceso.`,
  [k.FAILED_TO_LOAD_THE_CHATROOM]: `Error al cargar la sala de chat`,
  [k.CONVERSATION_NOT_FOUND]: `Conversación no encontrada`,
  [k.DEFINE_NEW]: `Definir nuevo`,
  [k.CHATROOMSEARCH]: `búsquedaSalaDeChat`,
  [k.X3]: `X`,
  [k.ADDITIONAL_COMMENTS]: `Comentarios adicionales`,
  [k.SKIP]: `Saltar`,
  [k.ARCHIVE]: `Archivar`,
  [k.THIS_CONVERSATION]: `esta conversación`,
  [k.ARCHIVE1]: `archivar`,
  [k.CROP_UPLOAD]: `Recortar y subir`,
  [k.FILEINPUT]: `entradaArchivo`,
  [k.BOOLEAN]: `Booleano`,
  [k.DETAILS_OBJECTIVE]: `Detalles / Objetivo`,
  [k.DUE_DATE]: `Fecha de vencimiento`,
  [k.START_DIRECT_CONVERSATION]: `Iniciar conversación directa`,
  [k.YOU_CANT_CHAT_WITH_YOURSELF]: `No puedes chatear contigo mismo`,
  [k.CHOOSE_USER_TO_CHAT_WITH]: `Elige un usuario para chatear`,
  [k.TO_INVITE_SOMEONE_EXTERNALLY1]: `Para invitar a alguien externamente, por favor usa su dirección de correo electrónico.
          ej.john.doe@acme.com`,
  [k.PARENTS]: `Padres`,
  [k.CHILDREN]: `Hijos`,
  [k.OVERDUE2]: `Atrasado (`,
  [k.COMPLETED2]: `Completado (`,
  [k.I]: `Yo`,
  [k.M_OWNER]: `Soy el propietario (`,
  [k.RELATED_CHILDREN]: `Hijos relacionados (`,
  [k.RELATED_PARENTS]: `Padres relacionados (`,
  [k.SHOWING]: `Mostrando`,
  [k.OPTION]: `opción`,
  [k.I_M_THE_OWNER]: `Soy el propietario`,
  [k.RELATED]: `relacionado`,
  [k.DATEWARNING]: `advertenciaFecha`,
  [k.COMPLETED3]: `completado`,
  [k.INCOMPLETE1]: `incompleto`,
  [k.RELATED1]: `Relacionado`,
  [k.NO_LONGER_MATCHES_THE_LIST_F]: `Ya no coincide con la lista / filtro`,
  [k.SEND_A_DIRECT_MESSAGE]: `Enviar un mensaje directo`,
  [k.START_WITH_THIS_SOLUTION]: `Comenzar con esta solución`,
  [k.NO_MATCHING_RESULTS1]: `No hay resultados coincidentes`,
  [k.THERE_WEREN_T_ANY_CONVERSATION]: `No había ninguna conversación que coincidiera con tus filtros.`,
  [k.TRY_WIDENING_YOUR_SEARCH]: `Intenta ampliar tu búsqueda.`,
  [k._38]: `🎉`,
  [k.CONGRATULATIONS]: `¡Felicidades!`,
  [k.YOU_HAVE_UNREAD_CONVERSATION]: `Tienes 0 conversaciones no leídas aquí.`,
  [k.TRY_APPLYING_OTHER_FILTERS]: `Intenta aplicar otros filtros.`,
  [k.EDIT_FILTER]: `Editar filtro`,
  [k.SAVE_FILTER]: `Guardar filtro`,
  [k.ERROR_EDITING_FILTER]: `Error al editar filtro`,
  [k.FILTER_CANNOT_BE_SAVED]: `El filtro no se puede guardar`,
  [k.SORT_BY3]: `ORDENAR POR`,
  [k.CUSTOM]: `PERSONALIZADO`,
  [k.VIEW_AND_SELECT_FROM_ALL_STATU]: `Ver y seleccionar de todos los estados`,
  [k.STATUS2]: `ESTADO`,
  [k.CLOSEFILTER]: `cerrarFiltro`,
  [k.CLEAR]: `Limpiar`,
  [k._39]: `4`,
  [k.ALL_TYPES]: `TODOS LOS TIPOS`,
  [k.SAVED_FILTERS]: `FILTROS GUARDADOS`,
  [k.QUICK_FILTERED_LISTS]: `LISTAS FILTRADAS RÁPIDAS`,
  [k.SUGGESTED]: `SUGERIDO`,
  [k.ALL_CONVERSATIONS]: `todas las conversaciones`,
  [k.ALL_CONVERSATIONS1]: `Todas las conversaciones`,
  [k.FAVOURITES]: `favoritos`,
  [k.FAVOURITES1]: `Favoritos`,
  [k.STAR]: `estrella`,
  [k.ACTIVEQUICKFILTER]: `filtroRápidoActivo`,
  [k.DEFAULTQUICKFILTER]: `filtroRápidoPredeterminado`,
  [k.BACKARROW]: `flechaAtrás`,
  [k.CHANGE]: `Cambiar`,
  [k.CONVERSATION_TYPE_NOT_FOUND]: `Tipo de conversación no encontrado`,
  [k.ALL_PROCESSES]: `Todos los procesos`,
  [k.ALL_TASKS]: `Todas las tareas`,
  [k.ALL_APPROVALS]: `Todas las aprobaciones`,
  [k.SEARCH_CONVERSATION]: `Buscar conversación`,
  [k.ADD_A_DEPARTMENT]: `Agregar un departamento`,
  [k.SEARCH_FOR_DEPARTMENTS]: `Buscar departamentos`,
  [k.NO_DEPARTMENT]: `Sin departamento`,
  [k.CREATE_NEW_DEPARTMENT]: `Crear nuevo departamento`,
  [k.ENTER_YOUR_DEPARTMENT]: `Ingresa tu departamento`,
  [k.OBJECT1]: `Objeto`,
  [k.CLONE1]: `Clonar`,
  [k.ORGANISE]: `Organizar`,
  [k.CREATE_NEW_FOLDER]: `Crear nueva carpeta`,
  [k.NEW_FOLDER]: `Nueva carpeta`,
  [k.MEDIUM]: `medio`,
  [k.MOVE_HERE]: `Mover aquí`,
  [k.ALL_ORG_FILES]: `Todos los archivos de la organización`,
  [k.MY_DRIVE]: `Mi unidad`,
  [k.V1]: `v`,
  [k.CURRENT_VERSION]: `versión actual`,
  [k.MAKE_CURRENT1]: `hacer actual`,
  [k.VERSION_HISTORY]: `Historial de versiones`,
  [k.PIN]: `Fijar`,
  [k.TO_ACTIVE_CONVERSATION]: `a la conversación activa`,
  [k.PIN1]: `fijar`,
  [k.PIN_TO_ACTIVE_CONVERSATION]: `Fijar a la conversación activa`,
  [k.ORGANISE1]: `organizar`,
  [k.TRASH]: `Papelera`,
  [k.SEE_RELATED_CONVERSATIONS]: `Ver conversaciones relacionadas`,
  [k.SMALL]: `pequeño`,
  [k.FILE_NAME]: `Nombre del archivo`,
  [k.SIZE]: `Tamaño`,
  [k.LAST_MODIFIED]: `Última modificación`,
  [k.UPLOADED_BY]: `Subido por`,
  [k.MY_FILES]: `Mis archivos`,
  [k.ALL_FILES]: `Todos los archivos`,
  [k.PIN_CONVERSATION]: `Fijar conversación`,
  [k.PIN_FILE]: `Fijar archivo "`,
  [k.TO1]: `" a "`,
  [k.HEIC]: `heic`,
  [k.COVER]: `cubierta`,
  [k.ADD_MORE]: `Agregar más`,
  [k.OPTIONAL_COMMENT]: `Comentario opcional:`,
  [k.FILES_NOT_FOUND]: `Archivos no encontrados`,
  [k.NO_RESULT]: `Sin resultado`,
  [k.UPLOAD_FILE]: `Subir archivo`,
  [k.SORT_BY_ALPHABET]: `Ordenar por alfabeto`,
  [k.LAST_MODIFY]: `Última modificación`,
  [k.RECENTLY_VIEWED]: `Vistos recientemente`,
  [k.UPLOAD_A_FILE]: `Subir un archivo`,
  [k.UPLOAD_FILE1]: `Subir archivo`,
  [k.UPLOAD_NEW_VERSION1]: `Subir nueva versión`,
  [k.THIS_FILE_IS_SHARED_ON_A_FOLDE]: `Este archivo está compartido en una carpeta y con otros hilos.
                Subir una nueva versión cambiará todos estos archivos. (Se mantendrá el historial de versiones anteriores para las últimas 5 versiones)`,
  [k.IT_LOOKS_LIKE_YOU_ARE_UPLOADIN]: `Parece que estás subiendo una nueva versión del archivo "`,
  [k.IS_THAT_CORRECT]: `". ¿Es eso correcto?`,
  [k.ENTER_VERSION_NOTES_COMMENTS]: `Ingresa notas/comentarios de la versión (opcional)`,
  [k.CONTINUE]: `Continuar`,
  [k.NO_ORGS_WITH_GIVEN_NAME]: `No hay organizaciones con el nombre dado`,
  [k.DASHBOARD1]: `tablero`,
  [k.MANAGE1]: `administrar`,
  [k.CRASH]: `Crash`,
  [k.DISPLAYNAME_ASC]: `displayName:asc`,
  [k.ACTIVE]: `Activo`,
  [k.CLONE_ORG]: `Clonar organización`,
  [k.COPY_LINK]: `Copiar enlace`,
  [k.CREATE_NEW_ORG]: `Crear nueva organización`,
  [k.TEAM_NAME]: `Nombre del equipo`,
  [k.INVITE_YOUR_TEAM_MEMBERS]: `Invita a los miembros de tu equipo`,
  [k.TEAM_MEMBER_OPTIONAL]: `Miembro del equipo (opcional)`,
  [k.DOMAIN_NAME]: `Nombre de dominio`,
  [k.OPTIONAL1]: `(opcional)`,
  [k.ALLOW_ANYONE_WITH]: `Permitir a cualquiera con`,
  [k.EMAIL_TO_JOIN_THIS_TEAM]: `correo electrónico para unirse a este equipo.`,
  [k.ALLOW_ANYONE_WITH_GIVEN_DOMAIN]: `Permitir a cualquiera con el dominio dado unirse a este equipo`,
  [k.ORGDARK]: `orgDark`,
  [k.ORG2]: `org`,
  [k.CHANGE_PASSWORD]: `Cambiar contraseña`,
  [k.CIRCLECHECK]: `círculoVerificado`,
  [k.PASSWORD_DOES_NOT_MATCH]: `(La contraseña no coincide)`,
  [k.CHANGE_ORG]: `Cambiar organización`,
  [k.UPDATE_NAME]: `Actualizar nombre`,
  [k.CHANGE_PROFILE_PICTURE]: `Cambiar foto de perfil`,
  [k.CHANGE_PASSWORD1]: `Cambiar contraseña`,
  [k.ORG_SETTINGS1]: `Configuraciones de la organización`,
  [k.A_NEW_VERSION_OF_UNIFIZE_HAS_B]: `Se ha lanzado una nueva versión de Unifize.`,
  [k.UPDATE_NOW]: `ACTUALIZAR AHORA`,
  [k.VIEW_AS]: `Ver como`,
  [k.SETTING_UP_YOUR_PROFILE]: `Configurando tu perfil`,
  [k.PLEASE_COMPLETE_YOUR_PROFILE_B]: `Por favor completa tu perfil a continuación para que puedas comenzar a usar Unifize`,
  [k.FULL_NAME]: `Nombre completo`,
  [k.CREATE_PASSWORD]: `Crear contraseña`,
  [k.PROFILE_PIC]: `foto de perfil`,
  [k.IMAGE1]: `imagen/*`,
  [k.ADD_PROFILE_PHOTO_OPTIONAL]: `Agregar foto de perfil (opcional)`,
  [k.SOMETHING_WENT_WRONG]: `Algo salió mal :(`,
  [k.PLEASE_TRY_CLICKING_ON_THE_INV]: `Por favor intenta hacer clic en el enlace de invitación nuevamente!`,
  [k.SETTING_UP_UNIFIZE_FOR_YOU]: `Configurando Unifize para ti`,
  [k.VERIFYING_CREDENTIALS]: `Verificando credenciales`,
  [k.DELETED_A]: `eliminó un`,
  [k.FORWARDED_FROM]: `Reenviado desde`,
  [k.HID_A_MESSAGE]: `ocultó un mensaje.`,
  [k.UNHIDE]: `Mostrar`,
  [k.FIRST]: `primero`,
  [k.UNARCHIVE1]: `desarchivar`,
  [k.IMG_LOADING]: `cargandoImagen...`,
  [k.GRAY3]: `gris.50`,
  [k.UPDATED]: `Actualizado`,
  [k.CANCELLED_THIS_CONVERSATION]: `canceló esta conversación`,
  [k.CANCELLED1]: `cancelado`,
  [k.COMPLETED_THIS_CONVERSATION]: `completó esta conversación`,
  [k.THIS_IS_YOUR_DIRECT_MESSAGE_WI]: `Este es tu mensaje directo con @`,
  [k.THIS_CONVERSATION_IS_PRIVATE]: `Esta conversación es privada`,
  [k.REOPENED_THIS_CONVERSATION]: `reabrió esta conversación`,
  [k.REOPENED]: `reabierto`,
  [k.PHOTO1]: `Foto`,
  [k.STARTED_THIS_CONVERSATION]: `inició esta conversación`,
  [k.STARTED]: `iniciado`,
  [k.UPDATED_STATUS_OF_THIS_CONVERS]: `actualizó el estado de esta conversación a`,
  [k.UPDATED_STATUS_OF_THE_CONVERSA]: `actualizó el estado de la conversación`,
  [k.UPDATED_THE_DUE_DATE_TO]: `actualizó la fecha de vencimiento a`,
  [k.SET_THE_DUE_DATE_TO]: `estableció la fecha de vencimiento a`,
  [k.REMOVED_THE_DUE_DATE]: `eliminó la fecha de vencimiento`,
  [k.READ]: `leído`,
  [k.DELIVERED]: `entregado`,
  [k.TO2]: `Para:`,
  [k.CC1]: `Cc:`,
  [k.BCC1]: `Bcc:`,
  [k.NO_CONTENT]: `Sin contenido`,
  [k.ATTACHMENTS]: `adjuntos`,
  [k.ATTACHMENT]: `adjunto`,
  [k.HIDE_DETAILS]: `Ocultar detalles`,
  [k.VIEW_FULL_EMAIL]: `Ver correo electrónico completo`,
  [k.FILL_CHECKLIST_FROM_EMAIL]: `Complete la lista de verificación según el correo electrónico`,
  [k.LOADING2]: `Cargando ...`,
  [k.DELETED_A_FILE]: `eliminó un archivo.`,
  [k.MAIL]: `correo`,
  [k.UPLOADED_A_FILE]: `Subió un archivo`,
  [k.SENT_AN_EMAIL]: `Envió un correo electrónico`,
  [k.ADDED_A_FORM]: `agregó un formulario`,
  [k.TO_THE_CHECKLIST]: `a la lista de verificación`,
  [k.REMOVED_A_FORM]: `eliminó un formulario`,
  [k.FROM_THE_CHECKLIST1]: `de la lista de verificación`,
  [k.ADDED_PARENT]: `agregó padre`,
  [k.REMOVED_PARENT]: `eliminó padre`,
  [k.ADDED_CHILD]: `agregó hijo`,
  [k.REMOVED_CHILD]: `eliminó hijo`,
  [k.UPDATED_THE_OBJECTIVE_TO]: `actualizó el objetivo a:`,
  [k.ADDED_OBJECTIVE]: `agregó objetivo:`,
  [k.REMOVED_OBJECTIVE]: `eliminó objetivo`,
  [k.CHANGED_THE_OWNER_FROM]: `cambió el propietario de`,
  [k.ASSIGNED]: `asignado`,
  [k.AS_THE_OWNER]: `como el propietario`,
  [k.REMOVED]: `eliminado`,
  [k.ADDED_CHILD_CONVERSATION]: `agregó conversación hija`,
  [k.REMOVED_CHILD_CONVERSATION]: `eliminó conversación hija`,
  [k.UPDATED_THE_PRIORITY_FROM]: `actualizó la prioridad de`,
  [k.CHANGED_THE_PRIVACY_SETTING_TO]: `cambió la configuración de privacidad a`,
  [k.TURNED_OFF_REMINDERS_FOR_THIS]: `desactivó los recordatorios para esta conversación.`,
  [k.TURN_ON]: `Encender`,
  [k.TURNED_ON_REMINDERS_FOR_THIS_C]: `encendió los recordatorios para esta conversación.`,
  [k.TURN_OFF]: `Apagar`,
  [k.UNIFIZE_ASSISTANT]: `Asistente de Unifize`,
  [k.TURN_OFF_REMINDERS]: `Apagar recordatorios`,
  [k.REMINDERS_HAVE_BEEN_TURNED_OFF]: `Los recordatorios han sido apagados`,
  [k.OF_THIS_CONVERSATION]: `de esta conversación`,
  [k.CREATED_A_REVISION]: `creó una revisión`,
  [k.MADE]: `hecho`,
  [k.AS_THE_CURRENT_REVISION1]: `como la revisión actual.`,
  [k.FROM]: `de #`,
  [k.FILES2]: `archivos`,
  [k.STARTED_THIS_CONVERSATION_FROM]: `inició esta conversación desde`,
  [k.FORWARDED_FROM1]: `reenviado desde`,
  [k.SENT_THIS_MESSAGE_FROM]: `envió este mensaje desde`,
  [k.THREAD]: `hilo`,
  [k.DUE_ON]: `vence el`,
  [k.UPDATED1]: `actualizado`,
  [k.TITLE_TO]: `título a`,
  [k.FROM1]: `de`,
  [k.ADDED]: `agregado`,
  [k.THE]: `el`,
  [k.INVITED]: `invitado`,
  [k.TO_THE_CHATROOM]: `a la sala de chat`,
  [k.JOINED_THE_CONVERSATION]: `se unió a la conversación`,
  [k.CHECKMARK]: `marca de verificación`,
  [k.TODAY]: `Hoy`,
  [k.LOADMORE]: `cargar más`,
  [k.LOADING_MORE_CONVERSATIONS]: `Cargando más conversaciones . . .`,
  [k.SIGNATORIES]: `Firmantes`,
  [k._40]: `@`,
  [k.MESSAGEWITHMENTION]: `mensajeConMención`,
  [k.MESSAGEWITHOUTMENTION]: `mensajeSinMención`,
  [k.REPLY_AS_EMAIL]: `Responder como Correo Electrónico`,
  [k.FORWARD_AS_EMAIL]: `Reenviar como Correo Electrónico`,
  [k.START1]: `Comenzar`,
  [k.STEP_DISMISSED]: `Paso Descartado`,
  [k.UNDO]: `DESHACER`,
  [k.HEY]: `Oye`,
  [k.CAN_YOU_COMPLETE_THIS_STEP]: `, ¿puedes completar este paso?`,
  [k.DISMISS]: `Descartar`,
  [k.CHECKLIST_FIELD_FILL]: `CHECKLIST_FIELD_FILL`,
  [k.YOU_CAN_ACCESS_THE_CONVERSATIO]: `Puedes acceder a la conversación después de que un participante te dé acceso.`,
  [k.REQUEST_SENT]: `Solicitud Enviada`,
  [k.REQUEST_ACCESS]: `Solicitar Acceso`,
  [k.THANK_YOU_FOR_YOUR_INTEREST]: `Gracias por tu interés`,
  [k.OUR_SALES_TEAM_WILL_GET_IN_TOU]: `Nuestro equipo de ventas se pondrá en contacto pronto`,
  [k.VALIDATING_YOUR_EMAIL]: `Validando tu correo electrónico`,
  [k.SET_UP_NEW_ORG_ON_UNIFIZE]: `Configurar nueva Organización en Unifize`,
  [k.PLEASE_ENTER_YOUR_WORK_EMAIL_S]: `Por favor ingresa tu correo electrónico de trabajo para que podamos conectarte a tu
            espacio privado de la organización.`,
  [k.TRY_SIGNING_IN]: `Intenta iniciar sesión`,
  [k.BY_CLICKING_SIGN_UP_YOU_ACC]: `Al hacer clic en "Registrarse", aceptas nuestros`,
  [k.TERMS_OF_SERVICE]: `Términos de Servicio`,
  [k.AND_OUR]: `, y nuestros`,
  [k.PRIVACY_POLICY]: `Política de Privacidad`,
  [k.SENDING_VERIFICATION_MAIL]: `Enviando correo de verificación`,
  [k.ERROR_SENDING_VERIFICATION_MAI]: `Error al enviar correo de verificación`,
  [k.CLICK_HERE_TO_RESEND]: `Haz clic aquí para reenviar.`,
  [k.PLEASE_VERIFY_YOUR_EMAIL]: `Por favor verifica tu correo electrónico`,
  [k.PLEASE_OPEN_YOUR]: `.
            Por favor abre tu correo electrónico y haz clic en el enlace para configurarte en
            Unifize.`,
  [k.DIDN_T_GET_YOUR_EMAIL]: `¿No recibiste tu correo electrónico?`,
  [k.ERROR_VERIFYING_YOUR_LINK]: `Error al verificar tu enlace`,
  [k.CHECK_YOUR_INBOX_FOR_THE_LATES]: `Revisa tu bandeja de entrada para el correo más reciente de nosotros.`,
  [k.ENTER_EMAIL_ADDRESS_TO_SIGN_UP]: `Ingresa la dirección de correo electrónico para registrarte`,
  [k.YOU_WILL_RECEIVE_A_VERIFICATIO]: `Recibirás un correo de verificación dentro de unos minutos`,
  [k.DEPARTMENT]: `departamento`,
  [k.DEPARTMENT1]: `Departamento`,
  [k.PROCESS1]: `proceso`,
  [k.FILTERS1]: `FILTROS:`,
  [k.SHOW_TOTAL]: `Mostrar Total`,
  [k.SHOW_AVERAGE]: `Mostrar Promedio`,
  [k.LAST_UPDATED]: `Última actualización:`,
  [k.LAST_DAYS]: `Últimos 7 días`,
  [k.LAST_MONTH]: `Último mes`,
  [k.CHOOSE_DATE]: `Elegir fecha`,
  [k.LAST_WEEK]: `Última semana`,
  [k.ADD_DATE_RANGE_MANUALLY]: `Agregar rango de fechas manualmente`,
  [k.USER_NAME]: `Nombre de usuario`,
  [k.CONVERSATIONS_CREATED_DURING]: `Conversaciones creadas durante`,
  [k.WHERE_USER_IS_A_PARTICIPANT]: `Donde el usuario es un participante`,
  [k.NOW_COMPLETED]: `Ahora completado`,
  [k.STILL_PENDING]: `Todavía pendiente`,
  [k.CURRENT_PENDING_CONVERSATIONS]: `Conversaciones pendientes actuales`,
  [k.UNREAD_BY_USER]: `No leído por el usuario`,
  [k.OWNED_BY_USER]: `Propiedad del usuario`,
  [k.MARKED_AS_CRITICAL]: `Marcado como crítico`,
  [k.CONVERSATIONS_COMPLETED_DURING]: `Conversaciones completadas durante`,
  [k.CREATED_BY_USER]: `Creado por el usuario`,
  [k.COMPLETED_BY_USER]: `Completado por el usuario`,
  [k.LAST_ACTIVE]: `Última activo`,
  [k.AVERAGE]: `Promedio:`,
  [k.TOTAL]: `Total:`,
  [k.CONTACTS]: `Contactos`,
  [k.LOCK_GROUP]: `Bloquear Grupo`,
  [k.ONLY_THE_GROUP_OWNER_CAN_MAKE]: `Solo el propietario del grupo puede hacer cambios en un grupo bloqueado`,
  [k.EDIT_GROUP]: `Editar Grupo`,
  [k.CREATE_NEW_GROUP]: `Crear Nuevo Grupo`,
  [k.GROUP_NAME]: `Nombre del Grupo`,
  [k.ADD_PARTICIPANTS1]: `Agregar Participantes`,
  [k.GROUP_OWNERS]: `Propietarios del Grupo`,
  [k.CREATE_GROUP]: `Crear Grupo`,
  [k.GROUP_LOGO]: `logo del grupo`,
  [k.GROUP_NAME1]: `Nombre del grupo`,
  [k.GROUP_OWNERS1]: `Propietarios del grupo`,
  [k.ADDED_ON]: `Agregado el`,
  [k.RESET_PASSWORD]: `Restablecer Contraseña`,
  [k.RESEND_INVITE]: `Reenviar Invitación`,
  [k.MMM_DD_YYYY_HH_MM_A1]: `MMM DD, YYYY hh:mm A`,
  [k.DATE_COMPLETED]: `Fecha Completada`,
  [k.GROUPS1]: `Grupos (`,
  [k.PROCESSES1]: `Procesos (`,
  [k.ONBOARDING_PROGRESS]: `Progreso de Incorporación`,
  [k.PHONE_NUMBER]: `Número de Teléfono`,
  [k.MESSAGE1]: `Mensaje`,
  [k.ENABLE]: `Habilitar`,
  [k.ADMIN]: `admin`,
  [k.MAKE_MEMBER]: `Hacer miembro`,
  [k.MAKE_ADMIN]: `Hacer Admin`,
  [k.MMM_DD_YYYY_H_MM_A]: `MMM DD, YYYY h:mm a`,
  [k.MMM_DD_YYYY_H_MM_A1]: `MMM DD, YYYY h:mm A`,
  [k.COLUMN2]: `columna-`,
  [k.ROLE]: `rol`,
  [k.VIEW_PROFILE]: `Ver Perfil`,
  [k.DIRECT_MESSAGE]: `Mensaje Directo`,
  [k.PEOPLE1]: `Personas`,
  [k.ADMIN1]: `¿admin?`,
  [k.MEMBER2]: `¿miembro?`,
  [k.DISABLING_A_USER_S_ACCOUNT_WIL]: `Desactivar la cuenta de un usuario eliminará todos sus datos en caché.`,
  [k.MAKE]: `Hacer`,
  [k.S_ACCOUNT]: `cuenta`,
  [k.USER_EXAMPLE_COM]: `usuario@ejemplo.com`,
  [k.ENTER_DISPLAY_NAME]: `Ingresa el Nombre a Mostrar`,
  [k.IN]: `en`,
  [k.SEARCH_FOR_COUNTRY]: `Buscar por país`,
  [k.NO_PHONE_NUMBER]: `Sin Número de Teléfono`,
  [k.ME2]: `yo`,
  [k.CAN_T_FIND_ANYONE_WITH_THIS_NA]: `No puedo encontrar a nadie con este nombre.`,
  [k.CAN_T_FIND_SOMEONE]: `¿No puedes encontrar a alguien?`,
  [k.TO_UNIFIZE_VIA_EMAIL]: `a Unifize vía correo electrónico`,
  [k.INVITE_THEM_TO_UNIFIZE_VIA_EMA]: `Invítalos a Unifize vía correo electrónico`,
  [k.CIRCLE]: `círculo`,
  [k.ME_ALWAYS_SELECTS_THE_VIEWER]: `Yo (Siempre selecciona al espectador)`,
  [k.SEARCH_PEOPLE]: `Buscar Personas`,
  [k.E_G_JOHN_DOE_XYZ_COM_OR_JOHN]: `p.ej. john.doe@xyz.com o John Doe`,
  [k.OUTLINE]: `contorno`,
  [k.VIA_EMAIL]: `vía correo electrónico`,
  [k.USERWITHDISPLAYNAME]: `usuarioConNombreAMostrar`,
  [k.SELECT_MEMBERS]: `Seleccionar Miembros`,
  [k.MEMBERS2]: `Miembros`,
  [k.MEMBER3]: `miembro-`,
  [k._41]: `30`,
  [k.PROFILE_PIC1]: `foto-de-perfil`,
  [k.USER_NOT_FOUND]: `Usuario no encontrado`,
  [k.DEFINE]: `Definir`,
  [k.DRAFT_PROCESS]: `Proceso Borrador`,
  [k.SEARCH_PROCESS]: `Buscar Proceso`,
  [k.CANNOT_REMOVE_STATUS]: `No se puede eliminar el estado "`,
  [k.THERE_ARE_OPEN_CONVERSATIONS_W]: `Hay conversaciones abiertas con ese estado. Necesitas cambiar el
          estado en todas esas conversaciones a un estado diferente antes de que podamos
          eliminar "`,
  [k.CONVERSATIONS_MARKED_AS]: `conversaciones marcadas como "`,
  [k.SEND_MAIL_WITHOUT_INVITES]: `Enviar correo sin invitaciones`,
  [k.SEND_WITH_INVITES]: `Enviar con invitaciones`,
  [k._42]: `99+`,
  [k.CHAT_HISTORY]: `historial-de-chat`,
  [k.BA]: `#1581BA`,
  [k._43]: `00`,
  [k.FFF]: `#fff`,
  [k._44]: `10`,
  [k._45]: `17`,
  [k._46]: `8`,
  [k._47]: `13`,
  [k._48]: `#888`,
  [k.EM3]: `1.5em`,
  [k.INITIAL]: `inicial`,
  [k.A_A_A]: `#2A2A2A`,
  [k.F_A]: `#f1a208`,
  [k.BBBBBB]: `#BBBBBB`,
  [k.A_A_A1]: `#2a2a2a`,
  [k.URL_AVATAR_PATTERN]: `url(#avatar-pattern-`,
  [k.AVATAR_PATTERN]: `avatar-pattern-`,
  [k.OBJECTBOUNDINGBOX]: `objectBoundingBox`,
  [k.SCALE]: `scale(.00163)`,
  [k.GROUP_AVATAR]: `#group-avatar-`,
  [k.GROUP_AVATAR1]: `group-avatar-`,
  [k._49]: `612`,
  [k._50]: `7`,
  [k._51]: `18`,
  [k._52]: `0 0`,
  [k._53]: `12`,
  [k._54]: `24`,
  [k._55]: `#888888`,
  [k.F_ED]: `#2F80ED`,
  [k.IC_FORMAT_ALIGN_CENTER_PX]: `ic_format_align_center_24px`,
  [k.IC_FORMAT_ALIGN_JUSTIFY_PX]: `ic_format_align_justify_24px`,
  [k.IC_FORMAT_ALIGN_LEFT_PX]: `ic_format_align_left_24px`,
  [k.IC_FORMAT_ALIGN_RIGHT_PX]: `ic_format_align_right_24px`,
  [k.IC_FORMAT_LIST_BULLETED_PX]: `ic_format_list_bulleted_24px`,
  [k.IC_FORMAT_ITALIC_PX]: `ic_format_italic_24px`,
  [k.IC_FORMAT_LIST_NUMBERED_PX]: `ic_format_list_numbered_24px`,
  [k.IC_FORMAT_UNDERLINED_PX]: `ic_format_underlined_24px`,
  [k.UNABLE_TO_LOAD_DOCUMENT]: `No se puede cargar el documento.`,
  [k._56]: `…`,
  [k.SCREENSHOT]: `Captura de pantalla`,
  [k.RESET_HIGHLIGHTS]: `Restablecer resaltados`,
  [k.FUNCTION]: `función`,
  [k.PAGE1]: `.página`,
  [k.ADD_COMMENT]: `Agregar comentario`,
  [k.ADD_A_COMMENT]: `Agregar un Comentario`,
  [k.START_TYPING_YOUR_COMMENT_HERE]: `Empieza a escribir tu comentario aquí...`,
  [k.ADD_COMMENT1]: `Agregar Comentario`,
  [k.CUSTOM1]: `personalizado`,
  [k.AUTOMATIC_ZOOM]: `Zoom Automático`,
  [k.ACTUAL_SIZE]: `Tamaño Real`,
  [k.PAGE_FIT]: `Ajustar a Página`,
  [k.PAGE_WIDTH]: `Ancho de Página`,
  [k._57]: `75%`,
  [k._58]: `125%`,
  [k._59]: `150%`,
  [k._60]: `200%`,
  [k._61]: `300%`,
  [k._62]: `400%`,
  [k.PAGE_FIT1]: `ajustar-a-página`,
  [k.PAGE_ACTUAL]: `tamaño-real`,
  [k.PAGE_WIDTH1]: `ancho-de-página`,
  [k.EMAIL_PLACEHOLDER]: `e.g. john.doe@unifize.com`,
  [k.NEW_PASSWORD_PLACEHOLDER]: `Introduce la nueva contraseña`,
  [k.FILES_TOOLTIP_TITLE]: `Archivos`,
  [k.CHECKLIST_TOOLTIP_TITLE]: `Lista de verificación`,
  [k.REFRESH_CHECKLIST_TOOLTIP_TITLE]: `Refrescar`,
  [k.OPEN_CHECKLIST_TOOLTIP_TITLE]: `Abrir Checklist`,
  [k.RESTRICTED_ROOM_MODAL_MESSAGE]: `No se pudo cargar el chatroom al que intentas acceder. ¡Por favor, inténtalo de nuevo en algún momento!`,
  [k.START_NEW]: `Iniciar nuevo`,
  [k.FEED]: `Alimentación`,
  [k.MORE_OPTIONS]: `Más opciones`,
  [k.GENERATE_JSON_SUCCESS]: `Generando JSON. El enlace de descarga se te enviará por correo electrónico en un par de minutos.`,
  [k.GENERATE_JSON_FAILURE]: `No se pudo generar json`,
  [k.PREVENT_REQUESTER_MESSAGE]: `Los solicitantes no pueden agregar firmas.`,
  [k.ENTER_A_TITLE_FOR_THIS_PROCESS]: `Ingrese un título para este proceso`,
  [k.TEXT]: `Texto`,
  [k.NUMBER]: `Número`,
  [k.DATE]: `Fecha`,
  [k.USER]: `Usuario`,
  [k.SECTION]: `Sección`,
  [k.SUBSECTION]: `Subsección`,
  [k.CONVERSATION2]: `Conversación`,
  [k.PARENT_CONVERSATION]: `Conversación Padre`,
  [k.CHILD_CONVERSATION]: `Conversación Hija`,
  [k.PDF]: `Generar PDF`,
  [k.FILE_UPLOAD]: `Subir archivo`,
  [k.FORM]: `Formulario`,
  [k.REVISION]: `Revisión`,
  [k.LINKED_FIELD]: `Campo enlazado`,
  [k.UPLOAD_FIELDS_FROM_CSV]: `Subir campos desde CSV`,
  [k.UPLOAD_FIELDS_JSON]: `Subir campos desde JSON`,
  [k.RICH_TEXT]: `Texto enriquecido`,
  [k.CHECKLIST_FIELD_TEXT_DESCRIPTION]: "Campo de texto libre",
  [k.CHECKLIST_FIELD_NUMBER_DESCRIPTION]:
    "Ingrese un número. Puede usarse para analizar totales",
  [k.CHECKLIST_FIELD_DATE_DESCRIPTION]:
    "Fecha ingresada por el usuario. Manual o selector de fecha.",
  [k.CHECKLIST_FIELD_USER_DESCRIPTION]:
    "Seleccione un usuario dentro de sus contactos u organización",
  [k.CHECKLIST_FIELD_SECTION_DESCRIPTION]:
    "Divida la lista de verificación utilizando secciones colapsables prominentes.",
  [k.CHECKLIST_FIELD_SUBSECTION_DESCRIPTION]:
    "Agregue una subsección colapsable dentro de una sección",
  [k.CHECKLIST_FIELD_CONVERSATION_DESCRIPTION]:
    "Vincule otro proceso como una conversación principal.",
  [k.CHECKLIST_FIELD_CHILD_CONVERSATION_DESCRIPTION]:
    "Vincule otro proceso como una conversación secundaria.",
  [k.CHECKLIST_FIELD_SELECT_DESCRIPTION]:
    "Selección desplegable de elementos. Opcional: Los usuarios pueden agregar elementos.",
  [k.CHECKLIST_FIELD_PDF_DESCRIPTION]:
    "Genere un pdf imprimible de la lista de verificación utilizando una plantilla.",
  [k.CHECKLIST_FIELD_FILE_DESCRIPTION]:
    "Adjuntar / subir documentos o imágenes al campo.",
  [k.CHECKLIST_FIELD_FORM_DESCRIPTION]:
    "Agregue un formulario preconfigurado. Útil para múltiples elementos de línea.",
  [k.CHECKLIST_FIELD_APPROVAL_DESCRIPTION]:
    "Aplique firmas digitales a procesos y documentos.",
  [k.CHECKLIST_FIELD_REVISION_DESCRIPTION]:
    "Cree y gestione revisiones de conversaciones",
  [k.CHECKLIST_FIELD_LINK_DESCRIPTION]:
    "Vincule a otro proceso (en ambas direcciones).",
  [k.CHECKLIST_FIELD_UPLOAD_FROM_CSV_DESCRIPTION]:
    "Subir una hoja de verificación de Excel para crear rápidamente una lista de verificación",
  [k.TOOLTIP_HOMESCREEN]: "Pantalla de inicio",
  [k.TOOLTIP_DASHBOARD]: "Tablero",
  [k.TOOLTIP_CONTACTS]: "Contactos",
  [k.TOOLTIP_MANAGE]: "Administrar",
  [k.TOOLTIP_DIRECT_MESSAGES]: "Mensajes directos",
  [k.MORE_OPTIONS]: "Más opciones",
  [k.SEND_RESPONSE_FROM_HERE]: "Enviar una respuesta desde aquí",
  [k.APPROVAL_PERMISSIONS]: "Permisos de aprobación",
  [k.APPROVAL_CAN_BE_CANCELLED_BY]:
    "La aprobación solo puede ser cancelada por",
  [k.APPROVAL_SETTINGS_NOT_CONFIGURED]:
    "Los ajustes de aprobación no han sido configurados",
  [k.DUE]: "Vencimiento",
  [k.PROCESS_WAS_DELETED]: "El proceso fue eliminado",
  [k.IMPORTED_WITH_ERRORS]: "Importado con error(es)",
  [k.SEARCH_PROCESS]: "Buscar Proceso",
  [k.ONLY_IMAGES_ARE_ALLOWED]: "Solo se permiten imágenes",
  [k.UNABLE_TO_CLEAR_DB]: "No se puede borrar la base de datos: ",
  [k.PREFERENCES]: "Preferencias",
  [k.LANGUAGE]: "Idioma",
  [k.LANGUAGE_SWITCHING_HINT]:
    "Seleccione el idioma que desea utilizar en Unifize.",
  [k.LANGUAGE_SUPPORT_HINT]:
    "Comuníquese con su administrador para habilitar el soporte de idiomas.",
  [k.FIELD_NAME]: "Nombre del campo",
  [k.ADD_NEW_FIELD]: "Add new field",
  [k.SETTINGS]: "Ajustes",
  [k.SHOW_HIDE_FIELD]: "Mostrar/Ocultar campo",
  [k.TRANSLATIONS]: `Traducciones`,
  [k.SUPPORTED_LANGUAGES]: `Idiomas admitidos`,
  [k.LANGUAGE]: `Idioma`,
  [k.ADD_NEW]: `+ Añadir Nuevo`,
  [k.NO_TRANSLATIONS_FOUND]: `No se encontraron traducciones.`,
  [k.CONTACT_SUPPORT]: `Contacta a Unifize para obtener soporte para más idiomas.`,
  [k.PREVIOUS]: `Anterior`,
  [k.PAGE_SIZE]: `Tamaño de Página`,
  [k.ENTER_PAGE_NUMBER]: `Ingrese el número de página`,
  [k.LANGUAGE_SWITCH]: `Cambiar Idioma`,
  [k.LANGUAGE_INFO]: `Los idiomas admitidos por su organización son:`,
  [k.ACTION]: `Acción`,
  [k.PATH]: `Ruta`,
  [k.FAILED_TO_SWITCH_LANGUAGE]: `No se pudo cambiar el idioma`,
  [k.ENTER_OPTIONAL_COMMENT_HERE]: "Ingrese un comentario opcional aquí",
  [k.SELECT_ALL_PENDING]: "Seleccionar todos los pendientes",
  [k.SELECT_ALL_COMPLETE]: "Seleccionar todos los completos",
  [k.PENDING_CONVERSATIONS]: "e.g. Conversaciones Pendientes",
  [k.NUM_PENDING_TASKS]: "e.g. Tienes #num tareas pendientes",
  [k.TAKE_ME_THERE]: "Llévame allí",
  [k.REFRESHED_CHART_DATA_NOT_AVAILABLE]:
    "Los datos del gráfico actualizados no están disponibles. Por favor, inténtelo de nuevo en un par de minutos.",
  [k.AVAILABLE_CHARTS]: "Gráficos disponibles",
  [k.SEARCH_CHARTS]: "Buscar gráficos",
  [k.CHART_ALREADY_EXISTS_IN_DASHBOARD]:
    "Este gráfico ya existe en el tablero actual",
  [k.FAILED_TO_LOAD_CHART]:
    "Error al cargar los datos del gráfico. Por favor, refresque después de un tiempo",
  [k.FAILED_TO_LOAD_CHART_DATA]:
    "Error al cargar el gráfico. Por favor, refresque después de un tiempo",
  [k.NO_DATA_TO_DISPLAY]: "No hay datos para mostrar",
  [k.DATE_UPDATED_SORT]: "FECHA ACTUALIZADA",
  [k.PRIORITY_SORT]: "PRIORIDAD",
  [k.STATUS_SORT]: "ESTADO",
  [k.DATE_CREATED_SORT]: "FECHA CREADA",
  [k.DUE_DATE_SORT]: "FECHA DE VENCIMIENTO",
  [k.RECENT_SORT]: "Reciente",
  [k.STALE_SORT]: "Obsoleto",
  [k.CRITICAL_SORT]: "Crítico",
  [k.LOW_SORT]: "Bajo",
  [k.PENDING_SORT]: "Pendiente",
  [k.COMPLETE_SORT]: "Completo",
  [k.NEWEST_SORT]: "Más reciente",
  [k.OLDEST_SORT]: "Más antiguo",
  [k.DUE_SOON_SORT]: "Pronto vence",
  [k.DUE_LATER_SORT]: "Vence más tarde",
  [k.ENTER_FILTER_NAME]: "Ingrese el nombre del filtro",
  [k.ENTER_GROUP_NAME]: "Ingrese el nombre del grupo",
  [k.ADD_OWNER]: "Añadir propietario",
  [k.ADD_CONTACTS]: "Añadir contactos",
  [k.ADD_TO_GROUP]: "Añadir al grupo",
  [k.APPROVAL_CAN_ONLY_BE_SIGNED_BY]:
    "La aprobación solo puede ser firmada por",
  [k.ENTER_PASSWORD]: "Ingrese contraseña",
  [k.ENTER_COMMENT_HERE]: "Ingrese un comentario aquí",
  [k.RESENDING_OTP_TO_YOUR_EMAIL]: "Reenviando OTP a tu correo electrónico",
  [k.OTP_SENT_TO_EMAIL]: "El OTP ha sido enviado a tu correo electrónico",
  [k.FAILED_TO_SEND_OTP]:
    "Error al enviar OTP. Por favor, inténtalo de nuevo más tarde",
  [k.ENTER_OTP]: "Ingrese OTP de 6 dígitos",
  [k.INVITE_RECIPIENTS_TO_UNIFIZE]:
    "¿Quieres invitar a los destinatarios a Unifize?",
  [k.SEARCH_PEOPLE_OR_GROUPS]: "Buscar personas o grupos",
  [k.SELECT_ROLE_FOR_NEW_PARTICIPANT]:
    "Seleccionar rol para nuevos participantes",
  [k.ALL_COMMENTS_AND_MESSAGES]: "Todos los comentarios y mensajes",
  [k.SEARCH_PICKLIST]: "Buscar lista de selección",
  [k.ENTER_TITLE_FOR_NEW_ITEM]: "Ingrese título para nuevo elemento",
  [k.MARK_AS_FAVOURITE]: "Marcar como favorito",
  [k.REVISION_PERMISSIONS]: "Permisos de revisión",
  [k.REVISIONS_CAN_BE_CREATED_BY]: "Las revisiones solo pueden ser creadas por",
  [k.REVISION_SETTINGS_NOT_CONFIGURED]:
    "Los ajustes de revisión no han sido configurados",
  [k.ENTER_YOUR_NAME]: "Ingrese su nombre",
  [k.NEW_PASSWORD]: "Nueva contraseña",
  [k.GENERATE_PDF]: "Generar PDF",
  [k.MS_ADMIN_NOT_ENABLED]:
    "El administrador de Microsoft 365 no está habilitado",
  [k.UNABLE_TO_CREATE_FILE]: "No se puede crear el archivo",
  [k.MS_NOT_ENABLED]: "Microsoft 365 no está habilitado",
  [k.BLANK]: "En blanco",
  [k.NON_BLANK]: "No en blanco",
  [k.CANCEL_REQUEST]: "Cancelar Solicitud",
  [k.CANCEL_APPROVAL]: "Cancelar Aprobación",
  [k.OPEN]: "Abrir",
  [k.ENTER_OPTIONAL_TITLE]: "Ingrese título opcional",
  [k.ENTER_ADDITIONAL_COMMENTS]: "Ingrese comentarios adicionales",
  [k.PENDING_INQUIRIES_FOR_STEAM]:
    "p. ej. Consultas pendientes para turbinas de vapor",
  [k.GRAPHICAL_REPRESENTATION_THE_PENDING_ENQUIRIES]:
    "p. ej. Representación gráfica de las consultas pendientes para turbinas de vapor categorizadas por propietario",
  [k.SELECT_A_CHART_FOR]: "Seleccionar un gráfico para",
  [k.COUNT_OF_CONVERSATIONS]: "Recuento de conversaciones",
  [k.FOLLOWING_CHARTS_REPRESENT_CONVERSATIONS]:
    "Los siguientes gráficos representan el número de conversaciones subyacentes",
  [k.COUNT_OF_FORMS]: "Recuento de formularios",
  [k.FOLLOWING_CHARTS_REPRESENT_FORMS]:
    "Los siguientes gráficos representan el número de formularios subyacentes",
  [k.SUM_OF_NUMERIC_FIELD]: "Suma de campo numérico",
  [k.USE_CHARTS_FOR_TOTALS]:
    "Use estos gráficos cuando necesite el total de $ gastados o total de horas, etc.",
  [k.TIME_TRENDS]: "Tendencias temporales",
  [k.USE_CHARTS_FOR_TRENDS]:
    "Use estos gráficos cuando necesite ver tendencias de recuentos o valores a lo largo del tiempo",
  [k.COMPARISON_CHART]: "Gráfico de comparación",
  [k.USE_CHARTS_FOR_COMPARISON]:
    "Use estos gráficos cuando necesite comparar dos o más conjuntos de datos. Ej. tasa de defectos vs datos de producción",
  [k.PIE_CHART]: "Gráfico de Pie",
  [k.BAR_CHART]: "Gráfico de Barras",
  [k.CHECKLIST_FIELDS]: "Campos de Checklist",
  [k.CONVERSATION_ATTRIBUTES]: "Atributos de Conversación",
  [k.REFRESHED_PROCESS_TABLE]: "Tabla de proceso actualizada",
  [k.NEW_PROCESS_TEMPLATE]: "Nueva Plantilla de Proceso",
  [k.IMPORT]: "Importar",
  [k.DISPLAY_NAME]: "Nombre",
  [k.EMAIL_ID]: "Correo electrónico",
  [k.ROLE1]: "Rol",
  [k.DEPARTMENT]: "Departamento",
  [k.PHONE_NUMBER]: "Número de teléfono",
  [k.GROUPS]: "Grupos",
  [k.LAST_LOGGED_IN]: "Último inicio de sesión",
  [k.DATE_INVITED]: "Fecha de invitación",
  [k.DATE_JOINED]: "Fecha de unión",
  [k.SEARCH_FOR_ANYTHING]: "Buscar cualquier cosa",
  [k.DATE_UPDATED]: "Fecha de actualización",
  [k.PRIORITY]: "Prioridad",
  [k.STATUS]: "Estado",
  [k.DATE_CREATED]: "Fecha de creación",
  [k.DUE_DATE]: "Fecha de vencimiento",
  [k.ALL_RELATED]: "Todos Relacionados",
  [k.OPEN_REVISIONS]: "Abrir Revisiones",
  [k.EXPORT]: "Exportar",
  [k.REMOVE_CHART_FROM_DASHBOARD]: "Eliminar Gráfico del Dashboard",
  [k.REMOVE_CHART]: "Eliminar Gráfico",
  [k.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_CHART]:
    "¿Estás seguro de que deseas eliminar este gráfico del dashboard?",
  [k.TOMORROW]: "Mañana",
  [k.YESTERDAY]: "Ayer",
  [k.ALL_EMBEDDED_RECORDS]: "Todos los registros incrustados",
  [k.ERROR_MODIFYING_GROUP]: "Error al modificar el grupo",
  [k.SEARCH_ORG]: "Buscar Org",
  [k.ERROR_CREATING_CONVERSATION]: "Error al crear conversación",
  [k.DISABLE_AND_CLEAR_ALL_DATA]: "Deshabilitar y limpiar todos los datos",
  [k.INVITE_SENT]: "Invitación enviada",
  [k.INVITE_CLICKED]: "Invitación aceptada",
  [k.EMAIL_PASSWORD_REGISTRATION]:
    "Registro de correo electrónico y contraseña",
  [k.PHONE_DEPT_REGISTRATION]: "Registro de teléfono y departamento",
  [k.SIGNIN_MOBILE]: "Iniciar sesión (Móvil)",
  [k.SIGNIN_WEB]: "Iniciar sesión (Web)",
  [k.USE_THIS_SECTION_UPCOMING_TASKS]:
    "Usa esta sección para mostrar tareas futuras",
  [k.HAS_USED_PLATFORM]: "ha usado la plataforma",
  [k.IS_PART_OF_GROUP]: "Es parte del grupo",
  [k.IS_NOT_PART_OF_GROUP]: "No es parte del grupo",
  [k.UNTITLED_USER_SEGMENT]: "Segmento de usuario sin título",
  [k.SEARCH_DOMAINS]: "Buscar dominios",
  [k.APPS_DESCRIPTION]:
    "Integra con tus aplicaciones favoritas y potencia tu flujo de trabajo",
  [k.NO_APPS_FOUND]: "No se encontraron aplicaciones",
  [k.UNABLE_FETCH_INTEGRATIONS]: "No se pudieron obtener las integraciones",
  [k.UPDATE_INTEGRATION_FAILED]: "Error al actualizar la integración",
  [k.DISCONNECT_INTEGRATION_FAILED]: "Error al desconectar la integración",
  [k.ENTER_TEXT]: "Ingresar texto",
  [k.ENTER_NUMBER]: "Ingrese número",
  [k.NEW_FORM_TEMPLATE]: "Nuevo plantilla de formulario",
  [k.SELECT_CHECKLIST_FIELD]: "Seleccionar campo de checklist",
  [k.ENTER_CONVERSATION_TITLE]: "Ingrese título de conversación",
  [k.TURN_OFF_REMINDERS_CONTENT]:
    "¿Estás seguro de que deseas desactivar los recordatorios?",
  [k.ENTER_LABEL]: "Introducir etiqueta",
  [k.THIS_WILL_BE_X_AXIS_SERIES]: "Esto será la serie del eje x",
  [k.UNTITLED_SERIES]: "Serie sin título",
  [k.AREA]: "Área",
  [k.RATE]: "Tasa",
  [k.LINE]: "Línea",
  [k.ENTER_TRENDLINE_LABEL]: "Ingrese etiqueta de tendencia",
  [k.TOTAL_OF_NUMERICAL_FIELD]: "Total de un campo numérico",
  [k.AVERAGE_OF_NUMERICAL_FIELD]: "Promedio de un campo numérico",
  [k.NESTED_FIELDS_DESCRIPTION]:
    "Para campos anidados, suma dentro de un formulario/campo vinculado primero, luego promedia los resultados",
  [k.RECORD_COUNT]: "Recuento de registros",
  [k.SUM]: "Suma",
  [k.AVERAGE]: "Promedio",
  [k.AVERAGE_OF_SUM]: "Promedio de suma",
  [k.ALL_RECORDS_FILTER]: "Filtro de todos los registros",
  [k.ALL_RECORDS_FILTER_DESCRIPTION]:
    " - Si esta opción está desactivada (predeterminada): Solo los registros del proceso principal serán filtrados - incluso si contiene múltiples valores - Si esta opción está activada: Todos los registros también serán filtrados, por lo que solo verás registros de formulario que contengan el valor ",
  [k.SEARCH_TYPE]: "Tipo de búsqueda",
  [k.SUBTITLE]: "Subtítulo",
  [k.YOU_HAVE_PENDING_TASKS]: "p.ej. Tienes tareas pendientes",
  [k.YOU_HAVE_NUM_PENDING_TASKS]: "p.ej. Tienes #num tareas pendientes",
  [k.SEARCH_TILES]: "Buscar azulejos",
  [k.SELECT_ITEMS]: "Seleccionar elementos",
  [k.WRONG_PASSWORD_MESSAGE]:
    "(Mínimo 8 caracteres, 1 carácter en minúscula, 1 carácter en mayúscula y 1 carácter numérico)",
  [k.CONFIRM_PASSWORD]: "Confirmar contraseña",
  [k.UNTITLED]: "Untitled",
  [k.CREATE_REPORT_TILE]: "Crear mosaico de informes",
  [k.TILE_TITLE]: "Título del azulejo",
  [k.SELECT_REPORT]: "Seleccionar informe",
  [k.ADD_REPORT]: "Agregar un informe",
  [k.SEARCH_ROLES]: "Buscar roles",
  [k.SEARCH_DEPARTMENTS]: "Buscar departamentos",
  [k.SEARCH_GROUPS]: "Buscar grupos",
  [k.NO_FILTERS_APPLIED]: "No se han aplicado filtros",
  [k.A_FEW_SECONDS_AGO]: "hace unos segundos",
  [k.A_FEW_MINUTES_AGO]: "hace unos minutos"
};
