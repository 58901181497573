// @flow
import React, { useMemo } from "react";

import { messageActionTypes } from "src/constants/chatroom";
import {
  addUpdateToMessageFilter,
  removeUpdateFromMessageFilter
} from "src/actions/chatroom";

import type { Action } from "src/types";

import { CheckboxesContainer } from "./styles";
import FilterSubmenu from "./FilterSubmenu";
import Checkbox from "./FilterCheckbox";

type Props = {
  dispatch: (action: Action, payload: any) => void,
  filteredRecords: Array<string>,
  menuRef: React$ElementRef<any>
};

const RecordUpdatesMenu = ({ dispatch, filteredRecords, menuRef }: Props) => {
  const recordFilters = useMemo(
    () =>
      Object.keys(messageActionTypes).reduce((keyStore, key) => {
        keyStore[key] = key;
        return keyStore;
      }, {}),
    [messageActionTypes]
  );

  return (
    <FilterSubmenu menuRef={menuRef}>
      <CheckboxesContainer>
        <Checkbox
          label="Messages"
          checked={!filteredRecords.includes(recordFilters.messages)}
          onChange={(checked, e) => {
            e && e.stopPropagation();
            if (checked) {
              dispatch(removeUpdateFromMessageFilter(recordFilters.messages));
            } else {
              dispatch(addUpdateToMessageFilter(recordFilters.messages));
            }
          }}
        />
        <Checkbox
          label="Checklist updates"
          checked={!filteredRecords.includes(recordFilters.checklist)}
          onChange={(checked, e) => {
            e && e.stopPropagation();
            if (checked) {
              dispatch(removeUpdateFromMessageFilter(recordFilters.checklist));
            } else {
              dispatch(addUpdateToMessageFilter(recordFilters.checklist));
            }
          }}
        />
        <Checkbox
          label="Metadata updates"
          checked={!filteredRecords.includes(recordFilters.metadata)}
          onChange={(checked, e) => {
            e && e.stopPropagation();
            if (checked) {
              dispatch(removeUpdateFromMessageFilter(recordFilters.metadata));
            } else {
              dispatch(addUpdateToMessageFilter(recordFilters.metadata));
            }
          }}
        />
      </CheckboxesContainer>
    </FilterSubmenu>
  );
};

export default RecordUpdatesMenu;
