// @flow
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { getUserNames } from "src/reducers";
import {
  addAutomatorToMessageFilter,
  removeAutomatorFromMessageFilter
} from "src/actions/chatroom";

import type { UID, UserNames, Action, ChatroomMessageFilter } from "src/types";

import Checkbox from "./FilterCheckbox";
import { CheckboxesContainer } from "./styles";
import FilterSubmenu from "./FilterSubmenu";

type Props = {
  dispatch: (action: Action, payload: any) => void,
  participants: Array<UID>,
  automators: $PropertyType<ChatroomMessageFilter, "automators">,
  menuRef: React$ElementRef<any>
};

const AutomatorsMenu = ({
  dispatch,
  participants,
  automators,
  menuRef
}: Props) => {
  const allUserNames: UserNames = useSelector(({ app }) => getUserNames(app));
  const automatorParticipants = useMemo(() => {
    const automatorUIDs = Object.keys(automators);
    return automatorUIDs.length
      ? participants.filter(user => automatorUIDs.includes(user))
      : [];
  }, [automators, participants]);

  return (
    <FilterSubmenu menuRef={menuRef}>
      <CheckboxesContainer>
        {automatorParticipants.map((automator, index) => (
          <Checkbox
            key={`automatorFilterMenu_${index}_${automator}`}
            label={allUserNames[automator]?.displayName ?? "Unknown"}
            checked={!automators[automator]}
            onChange={(checked, e) => {
              e && e.stopPropagation();
              if (!checked) {
                dispatch(addAutomatorToMessageFilter(automator));
              } else {
                dispatch(removeAutomatorFromMessageFilter(automator));
              }
            }}
          />
        ))}
      </CheckboxesContainer>
    </FilterSubmenu>
  );
};

export default AutomatorsMenu;
