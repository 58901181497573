// @flow
import React, { useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";
import k from "src/i18n/keys";
import * as R from "ramda";

import { getAllParticipants, getChatroomMessageFilter } from "src/reducers";
import Icon from "src/icons";
import * as colors from "src/styles/constants/colors";

import type { ChatroomMessageFilter, UID } from "src/types";

import {
  MenuContainer,
  FilterMenuContent,
  FilterMenuItem,
  FilterSubmenuItem,
  ToggleIconContainer,
  MenuIconContainer,
  NotifyCircle
} from "./styles";
import UserFilterMenu from "./UserFilterMenu";
import RecordUpdatesMenu from "./RecordUpdatesMenu";
import AutomatorsMenu from "./AutomatorsMenu";

const Filter = () => {
  const [open, setOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState<?number>(null);
  const filterMenuRef = useRef(null);
  const dispatch = useDispatch();

  const chatroomParticipants: Array<UID> = useSelector(({ app }) =>
    R.uniq(getAllParticipants(app))
  );
  const currentFilter: ChatroomMessageFilter = useSelector(({ app }) =>
    getChatroomMessageFilter(app)
  );

  const automatorParticipants = useMemo(() => {
    const automatorUIDs = Object.keys(currentFilter.automators);
    return chatroomParticipants.filter(participant =>
      automatorUIDs.includes(participant)
    );
  }, [currentFilter.automators, chatroomParticipants]);
  const userFilterApplied = useMemo(
    () => currentFilter.users.some(user => chatroomParticipants.includes(user)),
    [currentFilter.users, chatroomParticipants]
  );
  const automatorFilterApplied = useMemo(
    () =>
      automatorParticipants.length !== 0 &&
      Object.keys(currentFilter.automators).some(
        user => currentFilter.automators[user]
      ),
    [currentFilter.automators, chatroomParticipants]
  );

  const onClick = (e: Event) => {
    e.stopPropagation();
    setOpen(!open);
  };
  const toggleMenu = (e: Event, index: number) => {
    e.stopPropagation();
    setActiveSubmenu(_ => (_ === index ? null : index));
  };

  return (
    <MenuContainer ref={filterMenuRef}>
      <FilterMenuItem onClick={onClick} tabIndex={0} onKeyPress={onClick}>
        <MenuIconContainer>
          {currentFilter.records.length ||
          userFilterApplied ||
          automatorFilterApplied ? (
            <NotifyCircle left="55" bottom="55" />
          ) : null}
          <Icon type="chatFilter" fill={colors.active} />
        </MenuIconContainer>
        <span>{i18n.t(k.FILTER)}</span>
        {open ? (
          <ToggleIconContainer>
            <Icon type="caretUp" />
          </ToggleIconContainer>
        ) : (
          <ToggleIconContainer>
            <Icon type="caretDown" />
          </ToggleIconContainer>
        )}
      </FilterMenuItem>
      {open && (
        <FilterMenuContent>
          {chatroomParticipants.length !== 0 && (
            <FilterSubmenuItem
              onMouseEnter={e => {
                e.stopPropagation();
                setActiveSubmenu(0);
              }}
              onMouseLeave={e => {
                e.stopPropagation();
                setActiveSubmenu(null);
              }}
              tabIndex={0}
              onClick={(e: Event) => e.stopPropagation()}
              onKeyDown={e => toggleMenu(e, 0)}
            >
              <MenuIconContainer>
                {userFilterApplied ? (
                  <NotifyCircle right="65" bottom="55" />
                ) : null}
                <Icon type="chatFilterUser" fill={colors.active} />
                {i18n.t(k.USER)}
              </MenuIconContainer>
              <Icon type="chevronRight" />
              {activeSubmenu === 0 && (
                <UserFilterMenu
                  dispatch={dispatch}
                  participants={chatroomParticipants}
                  filteredUsers={currentFilter.users}
                  automatorUIDs={Object.keys(currentFilter.automators)}
                  menuRef={filterMenuRef}
                />
              )}
            </FilterSubmenuItem>
          )}
          <FilterSubmenuItem
            onMouseEnter={e => {
              e.stopPropagation();
              setActiveSubmenu(1);
            }}
            onMouseLeave={e => {
              e.stopPropagation();
              setActiveSubmenu(null);
            }}
            tabIndex={0}
            onClick={(e: Event) => e.stopPropagation()}
            onKeyDown={e => toggleMenu(e, 1)}
          >
            <MenuIconContainer>
              {currentFilter.records.length ? (
                <NotifyCircle right="84" bottom="55" />
              ) : null}
              <Icon type="chatFilterRecordUpdates" fill={colors.active} />
              {i18n.t(k.CONVERSATIONS)}
            </MenuIconContainer>
            <Icon type="chevronRight" />
            {activeSubmenu === 1 && (
              <RecordUpdatesMenu
                dispatch={dispatch}
                filteredRecords={currentFilter.records}
                menuRef={filterMenuRef}
              />
            )}
          </FilterSubmenuItem>
          {automatorParticipants.length !== 0 && (
            <FilterSubmenuItem
              onMouseEnter={e => {
                e.stopPropagation();
                automatorParticipants.length && setActiveSubmenu(2);
              }}
              onMouseLeave={e => {
                e.stopPropagation();
                automatorParticipants.length && setActiveSubmenu(null);
              }}
              tabIndex={0}
              onClick={(e: Event) => e.stopPropagation()}
              onKeyDown={e =>
                automatorParticipants.length !== 0 ? toggleMenu(e, 2) : {}
              }
              disabled={automatorParticipants.length === 0}
            >
              <MenuIconContainer>
                {automatorFilterApplied ? (
                  <NotifyCircle right="82" bottom="55" />
                ) : null}
                <Icon type="chatFilterUser" fill={colors.active} />
                Automators
              </MenuIconContainer>
              <Icon type="chevronRight" />
              {activeSubmenu === 2 && (
                <AutomatorsMenu
                  dispatch={dispatch}
                  participants={chatroomParticipants}
                  automators={currentFilter.automators}
                  menuRef={filterMenuRef}
                />
              )}
            </FilterSubmenuItem>
          )}
        </FilterMenuContent>
      )}
    </MenuContainer>
  );
};

export default Filter;
