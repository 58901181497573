// @flow
import React from "react";

const ChatroomFilter = ({ fill }: { fill?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_698_1068"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_698_1068)">
      <path
        d="M3.33333 13.3334V8.66675H2V7.33342H6V8.66675H4.66667V13.3334H3.33333ZM3.33333 6.00008V2.66675H4.66667V6.00008H3.33333ZM6 6.00008V4.66675H7.33333V2.66675H8.66667V4.66675H10V6.00008H6ZM7.33333 13.3334V7.33342H8.66667V13.3334H7.33333ZM11.3333 13.3334V11.3334H10V10.0001H14V11.3334H12.6667V13.3334H11.3333ZM11.3333 8.66675V2.66675H12.6667V8.66675H11.3333Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default ChatroomFilter;
