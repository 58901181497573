// @flow

import React, { useEffect, useState } from "react";

import { SubmenuContainer } from "./styles";

const SUBMENU_WIDTH = 206;

const FilterSubmenu = ({
  menuRef,
  children
}: {
  menuRef: React$ElementRef<any>,
  children: React$Node
}) => {
  const [openLeft, setOpenLeft] = useState(null);

  useEffect(() => {
    if (menuRef.current) {
      const rect = menuRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      if (rect.right + SUBMENU_WIDTH > viewportWidth) {
        setOpenLeft(true);
      } else {
        setOpenLeft(false);
      }
    }
  }, []);

  if (openLeft === null) return null;
  return (
    <SubmenuContainer openLeft={openLeft} width={SUBMENU_WIDTH}>
      {children}
    </SubmenuContainer>
  );
};

export default FilterSubmenu;
