// @flow

import * as notifications from "src/constants/notifications";
import { automatorName } from "src/constants/users";

import type { ChatroomMessageFilter } from "src/types";

export const chatroomAttributes = [
  {
    id: -10,
    label: "due-date"
  },
  {
    id: -9,
    label: "participant"
  },
  {
    id: -8,
    label: "title"
  },
  {
    id: -7,
    label: "age"
  },
  {
    id: -6,
    label: "children"
  },
  {
    id: -5,
    label: "parent"
  },
  {
    id: -4,
    label: "status"
  },
  {
    id: -3,
    label: "priority"
  },
  {
    id: -2,
    label: "creator"
  },
  {
    id: -1,
    label: "owner"
  }
];

export const chartChatroomAttributes = {
  "-8": {
    id: -8,
    label: "Title"
  },
  "-4": {
    id: -4,
    label: "Status"
  },
  "-3": {
    id: -3,
    label: "Priority"
  },
  "-2": {
    id: -2,
    label: "Creator"
  },
  "-1": {
    id: -1,
    label: "Owner"
  }
};

export const ageChartChatroomAttribute = {
  id: -7,
  name: "Age",
  type: "",
  multiValue: false,
  linkedProcessOrForm: "",
  linkedProcessOrFormId: null,
  linkedProcessOrFormType: "",
  linkedOrFormFieldName: "",
  linkedOrFormFieldId: null
};

export const defaultChatroomFilter: ChatroomMessageFilter = {
  users: [],
  automators: {},
  records: [],
  names: [automatorName]
};

const checklistNotifications = [
  notifications.CHECKLIST_FIELD_FILL,
  notifications.FORM_ADD,
  notifications.FORM_REMOVE
];

export const messageActionTypes = Object.freeze({
  messages: [undefined],
  checklist: checklistNotifications,
  // $FlowFixMe: Type annotation issues because of imported module as an object
  metadata: Object.values(notifications).filter(
    notification => !checklistNotifications.includes(notification)
  )
});

export const MESSAGE_FILTER_LOCALSTORAGE_KEY = "chatroomMessageFilter";
