// @flow
import * as R from "ramda";

import type { Dispatch } from "redux";
import type { Action } from "src/types";

import { getChatroomMessageFilter } from "src/selectors";
import { MESSAGE_FILTER_LOCALSTORAGE_KEY } from "src/constants/chatroom";

const messageFilterLocalStorageSync =
  (store: any) => (next: Dispatch<Action>) => (action: Action) => {
    const filter = getChatroomMessageFilter(store.getState());
    const result = next(action);
    const newFilter = getChatroomMessageFilter(store.getState());
    if (!R.equals(filter, newFilter)) {
      try {
        localStorage.setItem(
          MESSAGE_FILTER_LOCALSTORAGE_KEY,
          JSON.stringify(newFilter)
        );
      } catch (error) {
        console.error("Error saving to localStorage.", error);
      }
    }
    return result;
  };

export default messageFilterLocalStorageSync;
