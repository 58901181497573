// @flow
import React from "react";

import Checkbox from "src/components/Unifize/Checkbox";

import { FilterSubmenuElement as StyledFilterCheckbox } from "./styles";

type Props = {
  checked: boolean,
  onChange: (checked: boolean) => void
};

const FilterCheckbox = ({ checked, onChange, ...rest }: Props) => (
  <StyledFilterCheckbox>
    <Checkbox checked={checked} onChange={onChange} {...rest} />
  </StyledFilterCheckbox>
);

export default FilterCheckbox;
