// @flow
import React from "react";

const RecordUpdates = ({ fill }: { fill?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_698_949"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_698_949)">
      <path
        d="M3.99992 9.33325H9.33325V7.99992H3.99992V9.33325ZM3.99992 7.33325H11.9999V5.99992H3.99992V7.33325ZM3.99992 5.33325H11.9999V3.99992H3.99992V5.33325ZM1.33325 14.6666V2.66659C1.33325 2.29992 1.46381 1.98603 1.72492 1.72492C1.98603 1.46381 2.29992 1.33325 2.66659 1.33325H13.3333C13.6999 1.33325 14.0138 1.46381 14.2749 1.72492C14.536 1.98603 14.6666 2.29992 14.6666 2.66659V10.6666C14.6666 11.0333 14.536 11.3471 14.2749 11.6083C14.0138 11.8694 13.6999 11.9999 13.3333 11.9999H3.99992L1.33325 14.6666ZM3.43325 10.6666H13.3333V2.66659H2.66659V11.4166L3.43325 10.6666Z"
        fill={fill}
      />
    </g>
  </svg>
);

export default RecordUpdates;
